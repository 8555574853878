import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  shopDetail: {
    id: 0,
    name: null,
    logo: null,
    default_currency_id: 0,
    default_country_id: 0,
    default_language_id: 0,
    currency_name: null,
    currency_code: null,
    currency_left_symbol: null,
    currency_left_symbol: null,
    price_visible: true,
    tax_in_price: true,
    guest_checkout: true,
    seller_question: true,
    seo: null,
    social: null,
    theme_colors: null,
    fast_product_settings: null,
  },
};

export const shopSlice = createSlice({
  name: "shop",
  initialState: initialState,
  reducers: {
    setShopDetail: (state, { payload }) => {
      state.shopDetail = payload;
    },
  },
});

export const { setShopDetail } = shopSlice.actions;

export default shopSlice.reducer;
