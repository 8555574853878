import { lazy } from "react";
import {
  BuildingStoreIcon,
  HomeIcon,
  PuzzleIcon,
  BookIcon,
  CashIcon,
  DiscountIcon,
  BarcodeIcon,
  PageBuilderIcon,
  FileInvoiceIcon,
  ShoppingCartIcon,
  BrowserIcon,
  BrowserCheckIcon,
} from "../components/Icons";

const routes = [
  {
    path: "/",
    title: "Anasayfa",
    title_index: "title_homepage",
    Component: lazy(() => import("../pages/HomePage")),
    permissions: [],
    auth: true,
    exact: true,
    menu: false,
    icon: <HomeIcon />,
  },
  {
    path: "/dashboard",
    title: "Anasayfa",
    title_index: "title_dashboard",
    Component: lazy(() => import("../pages/Dashboard")),
    permissions: [100, 200],
    auth: true,
    exact: true,
    menu: true,
    icon: <HomeIcon />,
  },
  {
    path: "/catalog",
    title: "Ürün Katalog",
    title_index: "title_catalog",
    Component: lazy(() => import("../pages/Dashboard")),
    permissions: [100, 200],
    auth: true,
    exact: true,
    menu: true,
    icon: <BookIcon />,
    children: [
      {
        path: "#",
        title: "Kategoriler",
        title_index: "title_categories",
        permissions: [100, 400, 401, 402, 403, 404],
        auth: true,
        exact: true,
        menu: true,
        children: [
          {
            path: "/categories",
            title: "Kategoriler",
            title_index: "title_categories",
            Component: lazy(() => import("../pages/category/Categories")),
            permissions: [100, 400],
            auth: true,
            exact: true,
            menu: true,
          },
          {
            path: "/categories/add",
            title: "Kategori Ekle",
            title_index: "title_add_category",
            Component: lazy(() => import("../pages/category/AddCategory")),
            permissions: [100, 402],
            auth: true,
            exact: true,
            menu: true,
          },
          {
            path: "/categories/edit/:id",
            title: "Kategori Düzenle",
            title_index: "title_edit_category",
            Component: lazy(() => import("../pages/category/EditCategory")),
            permissions: [100, 403],
            auth: true,
            exact: true,
            menu: false,
          },
          {
            path: "/attributes",
            title: "Kategori Özellikleri",
            title_index: "title_category_attributes",
            Component: lazy(() => import("../pages/attribute/Attributes")),
            permissions: [100, 500],
            auth: true,
            exact: true,
            menu: true,
          },
          {
            path: "/attributes/add",
            title: "Kategori Özelliği Ekle",
            title_index: "title_add_category_attribute",
            Component: lazy(() => import("../pages/attribute/AddAttribute")),
            permissions: [100, 502],
            auth: true,
            exact: true,
            menu: true,
          },
          {
            path: "/attributes/edit/:id",
            title: "Kategori Özelliği Düzenle",
            title_index: "title_edit_category_attribute",
            Component: lazy(() => import("../pages/attribute/EditAttribute")),
            permissions: [100, 503],
            auth: true,
            exact: true,
            menu: false,
          },
        ],
      },
      {
        path: "#",
        title: "Ürünler",
        title_index: "title_products",
        permissions: [100, 600, 601, 602, 603, 604],
        auth: true,
        exact: true,
        menu: true,
        children: [
          {
            path: "/products",
            title: "Ürünler",
            title_index: "title_products",
            Component: lazy(() => import("../pages/product/Products")),
            permissions: [100, 600],
            auth: true,
            exact: true,
            menu: true,
          },
          {
            path: "/products/edit/:id",
            title: "Ürün Düzenle",
            title_index: "title_edit_product",
            Component: lazy(() => import("../pages/product/EditProduct")),
            permissions: [100, 603],
            auth: true,
            exact: true,
            menu: false,
          },
          {
            path: "/products/add",
            title: "Ürün Ekle",
            title_index: "title_add_product",
            Component: lazy(() => import("../pages/product/AddProduct")),
            permissions: [100, 602],
            auth: true,
            exact: true,
            menu: true,
          },
          {
            path: "/product-reviews",
            title: "Değerlendirmeler",
            title_index: "title_product_reviews",
            Component: lazy(() =>
              import("../pages/productReview/ProductReviews")
            ),
            permissions: [100, 700],
            auth: true,
            exact: true,
            menu: true,
          },
          {
            path: "/product-reviews/edit/:id",
            title: "Değerlendirme Düzenle",
            title_index: "title_edit_product_review",
            Component: lazy(() =>
              import("../pages/productReview/EditProductReview")
            ),
            permissions: [100, 703],
            auth: true,
            exact: true,
            menu: false,
          },
          // {
          //   path: "/products/matches",
          //   title: "Eşleşen Ürünler",
          //   title_index: "title_product_matches",
          //   Component: lazy(() => import("../pages/product/ProductMatches")),
          //   permissions: [100],
          //   auth: true,
          //   exact: true,
          //   menu: true,
          // },
          // {
          //   path: "/product-sortings",
          //   title: "Sıralama Seçenekleri",
          //   title_index: "title_product_sortings",
          //   Component: lazy(() =>
          //     import("../pages/productSorting/ProductSortings")
          //   ),
          //   permissions: [],
          //   auth: true,
          //   exact: true,
          //   menu: true,
          // },
        ],
      },
      {
        path: "#",
        title: "Ürün Vergileri",
        title_index: "title_taxes",
        permissions: [100, 800, 801, 802, 803, 804],
        auth: true,
        exact: true,
        menu: true,
        children: [
          {
            path: "/taxes",
            title: "Ürün Vergileri",
            title_index: "title_taxes",
            Component: lazy(() => import("../pages/tax/Taxes")),
            permissions: [100, 800],
            auth: true,
            exact: true,
            menu: true,
          },
          {
            path: "/taxes/add",
            title: "Ürün Vergisi Ekle",
            title_index: "title_add_tax",
            Component: lazy(() => import("../pages/tax/AddTax")),
            permissions: [100, 802],
            auth: true,
            exact: true,
            menu: true,
          },
          {
            path: "/taxes/edit/:id",
            title: "Ürün Vergisi Düzenle",
            title_index: "title_edit_tax",
            Component: lazy(() => import("../pages/tax/EditTax")),
            permissions: [100, 803],
            auth: true,
            exact: true,
            menu: false,
          },
        ],
      },
      {
        path: "#",
        title: "Birimler",
        title_index: "title_units",
        permissions: [100, 900, 901, 902, 903, 904],
        auth: true,
        exact: true,
        menu: true,
        children: [
          {
            path: "/units",
            title: "Birimler",
            title_index: "title_units",
            Component: lazy(() => import("../pages/unit/Units")),
            permissions: [100, 900],
            auth: true,
            exact: true,
            menu: true,
          },
          {
            path: "/units/add",
            title: "Birim Ekle",
            title_index: "title_add_unit",
            Component: lazy(() => import("../pages/unit/AddUnit")),
            permissions: [100, 902],
            auth: true,
            exact: true,
            menu: true,
          },
          {
            path: "/units/edit/:id",
            title: "Birim Düzenle",
            title_index: "title_edit_unit",
            Component: lazy(() => import("../pages/unit/EditUnit")),
            permissions: [100, 903],
            auth: true,
            exact: true,
            menu: false,
          },
        ],
      },
      {
        path: "#",
        title: "Gruplar",
        title_index: "title_groups",
        permissions: [100, 900, 901, 902, 903, 904],
        auth: true,
        exact: true,
        menu: true,
        children: [
          {
            path: "/groups",
            title: "Gruplar",
            title_index: "title_groups",
            Component: lazy(() => import("../pages/group/Groups")),
            permissions: [100, 900],
            auth: true,
            exact: true,
            menu: true,
          },
          {
            path: "/groups/add",
            title: "Grup Ekle",
            title_index: "title_add_group",
            Component: lazy(() => import("../pages/group/AddGroup")),
            permissions: [100, 902],
            auth: true,
            exact: true,
            menu: true,
          },
          {
            path: "/productgroup/add",
            title: "Grup Ekle",
            title_index: "title_add_product_group",
            Component: lazy(() =>
              import("../pages/productGroup/AddProductGroup")
            ),
            permissions: [100, 902],
            auth: true,
            exact: true,
            menu: true,
          },
          {
            path: "/productgroups",
            title: "Grupladığım Ürünler",
            title_index: "title_list_product_group",
            Component: lazy(() =>
              import("../pages/productGroup/ProductGroups")
            ),
            permissions: [100, 902],
            auth: true,
            exact: true,
            menu: true,
          },
          {
            path: "/productgroup/edit/:id",
            title: "Grupladığım Ürünleri Düzenle",
            title_index: "title_edit_product_group",
            Component: lazy(() =>
              import("../pages/productGroup/EditProductGroup")
            ),
            permissions: [100, 903],
            auth: true,
            exact: true,
            menu: false,
          },
          {
            path: "/groups/edit/:id",
            title: "Grup Düzenle",
            title_index: "title_edit_group",
            Component: lazy(() => import("../pages/group/EditGroup")),
            permissions: [100, 903],
            auth: true,
            exact: true,
            menu: false,
          },
        ],
      },
      {
        path: "#",
        title: "Varyasyonlar",
        title_index: "title_options",

        permissions: [100, 1000, 1001, 1002, 1003, 1004],
        auth: true,
        exact: true,
        menu: true,
        children: [
          {
            path: "/options",
            title: "Varyasyonlar",
            title_index: "title_options",
            Component: lazy(() => import("../pages/option/Options")),

            permissions: [100, 1000],
            auth: true,
            exact: true,
            menu: true,
          },
          {
            path: "/options/add",
            title: "Varyasyon Ekle",
            title_index: "title_add_option",
            Component: lazy(() => import("../pages/option/AddOption")),

            permissions: [100, 1002],
            auth: true,
            exact: true,
            menu: true,
          },
          {
            path: "/options/edit/:id",
            title: "Varyasyon Düzenle",
            title_index: "title_edit_option",
            Component: lazy(() => import("../pages/option/EditOption")),

            permissions: [100, 1001, 1003],
            auth: true,
            exact: true,
            menu: false,
          },
        ],
      },
      {
        path: "#",
        title: "Markalar",
        title_index: "title_brands",
        permissions: [100, 1100, 1101, 1102, 1103, 1104],
        auth: true,
        exact: true,
        menu: true,
        children: [
          {
            path: "/brands",
            title: "Markalar",
            title_index: "title_brands",
            Component: lazy(() => import("../pages/brand/Brands")),
            permissions: [100, 1100],
            auth: true,
            exact: true,
            menu: true,
          },
          {
            path: "/brands/add",
            title: "Marka Ekle",
            title_index: "title_add_brand",
            Component: lazy(() => import("../pages/brand/AddBrand")),
            permissions: [100, 1102],
            auth: true,
            exact: true,
            menu: true,
          },
          {
            path: "/brands/edit/:id",
            title: "Marka Düzenle",
            title_index: "title_edit_brand",
            Component: lazy(() => import("../pages/brand/EditBrand")),
            permissions: [100, 1101, 1103],
            auth: true,
            exact: true,
            menu: false,
          },
        ],
      },
      // {
      //   path: "#",
      //   title: "Mağazalar",
      //   title_index: "title_shops",
      //   permissions: [100, 2300, 2301, 2302, 2303, 2304],
      //   auth: true,
      //   exact: true,
      //   menu: false,
      //   children: [
      //     {
      //       path: "/shops",
      //       title: "Mağazalar",
      //       title_index: "title_shops",
      //       Component: lazy(() => import("../pages/shop/Shops")),
      //       permissions: [100, 2300],
      //       auth: true,
      //       exact: true,
      //       menu: true,
      //     },
      //     {
      //       path: "/shops/add",
      //       title: "Mağaza Ekle",
      //       title_index: "title_add_shop",
      //       Component: lazy(() => import("../pages/shop/AddShop")),
      //       permissions: [100, 2302],
      //       auth: true,
      //       exact: true,
      //       menu: true,
      //     },
      //     {
      //       path: "/shops/edit/:id",
      //       title: "Mağaza Düzenle",
      //       title_index: "title_edit_shop",
      //       Component: lazy(() => import("../pages/shop/EditShop")),
      //       permissions: [100, 2301, 2303],
      //       auth: true,
      //       exact: true,
      //       menu: false,
      //     },
      //   ],
      // },
      {
        path: "#",
        title: "Etiketler",
        title_index: "title_tags",
        permissions: [100, 1200, 1201, 1202, 1203, 1204],
        auth: true,
        exact: true,
        menu: true,
        children: [
          {
            path: "/tags",
            title: "Etiketler",
            title_index: "title_tags",
            Component: lazy(() => import("../pages/tag/Tags")),
            permissions: [100, 1200],
            auth: true,
            exact: true,
            menu: true,
          },
          {
            path: "/tags/add",
            title: "Etiket Ekle",
            title_index: "title_add_tag",
            Component: lazy(() => import("../pages/tag/AddTag")),
            permissions: [100, 1202],
            auth: true,
            exact: true,
            menu: true,
          },
          {
            path: "/tags/edit/:id",
            title: "Etiket Düzenle",
            title_index: "title_edit_tag",
            Component: lazy(() => import("../pages/tag/EditTag")),
            permissions: [100, 1201, 1203],
            auth: true,
            exact: true,
            menu: false,
          },
        ],
      },
      {
        path: "#",
        title: "Tedarikçiler",
        title_index: "title_suppliers",
        permissions: [100, 1300, 1301, 1302, 1303, 1304],
        auth: true,
        exact: true,
        menu: true,
        children: [
          {
            path: "/suppliers",
            title: "Tedarikçiler",
            title_index: "title_suppliers",
            Component: lazy(() => import("../pages/supplier/Suppliers")),
            permissions: [100, 1300],
            auth: true,
            exact: true,
            menu: true,
          },
          {
            path: "/suppliers/add",
            title: "Tedarikçi Ekle",
            title_index: "title_add_supplier",
            Component: lazy(() => import("../pages/supplier/AddSupplier")),
            permissions: [100, 1302],
            auth: true,
            exact: true,
            menu: true,
          },
          {
            path: "/suppliers/edit/:id",
            title: "Tedarikçi Düzenle",
            title_index: "title_edit_supplier",
            Component: lazy(() => import("../pages/supplier/EditSupplier")),
            permissions: [100, 1301, 1303],
            auth: true,
            exact: true,
            menu: false,
          },
        ],
      },
    ],
  },
  {
    path: "#",
    title: "Mağazam",
    title_index: "title_my_shop",
    permissions: [],
    auth: true,
    exact: true,
    menu: true,
    icon: <BuildingStoreIcon />,
    children: [
      {
        path: "#",
        title: "Kuponlar",
        title_index: "title_coupons",
        permissions: [],
        auth: true,
        exact: true,
        menu: true,
        children: [
          {
            path: "/coupons",
            title: "Kuponlar",
            title_index: "title_coupons",
            Component: lazy(() => import("../pages/coupon/Coupons")),
            permissions: [],
            auth: true,
            exact: true,
            menu: true,
          },
          {
            path: "/coupons/add",
            title: "Kupon Ekle",
            title_index: "title_add_coupon",
            Component: lazy(() => import("../pages/coupon/AddCoupon")),
            permissions: [],
            auth: true,
            exact: true,
            menu: true,
          },
          {
            path: "/coupons/edit/:id",
            title: "Kupon Düzenle",
            title_index: "title_edit_coupon",
            Component: lazy(() => import("../pages/coupon/EditCoupon")),
            permissions: [],
            auth: true,
            exact: true,
            menu: false,
          },
        ],
      },
      {
        path: "#",
        title: "Sipariş Bilgilendirme",
        title_index: "title_order_informings",
        permissions: [],
        auth: true,
        exact: true,
        menu: true,
        children: [
          {
            path: "/emails",
            title: "E-Postalar",
            title_index: "title_emails",
            Component: lazy(() => import("../pages/email/Emails")),
            permissions: [],
            auth: true,
            exact: true,
            menu: true,
          },
          {
            path: "/emails/edit/:id",
            title: "E-Posta Düzenle",
            title_index: "title_email_edit",
            Component: lazy(() => import("../pages/email/EditEmail")),
            permissions: [],
            auth: true,
            exact: true,
            menu: false,
          },
          {
            path: "/phone-messages",
            title: "Telefon Mesajları",
            title_index: "title_phone_messages",
            Component: lazy(() =>
              import("../pages/phoneMessage/phoneMessages")
            ),
            permissions: [],
            auth: true,
            exact: true,
            menu: true,
          },
          {
            path: "/phone-messages/edit/:id",
            title: "Mesaj Düzenle",
            title_index: "title_phone_messages_edit",
            Component: lazy(() =>
              import("../pages/phoneMessage/EditPhoneMessages")
            ),
            permissions: [],
            auth: true,
            exact: true,
            menu: false,
          },
        ],
      },

      {
        path: "#",
        title: "Kullanıcılar",
        title_index: "title_users",
        permissions: [
          100, 2000, 2001, 2002, 2003, 2004, 2100, 2101, 2102, 2103, 2104,
        ],

        auth: true,
        exact: true,
        menu: true,
        children: [
          {
            path: "/users",
            title: "Kullanıcılar",
            title_index: "title_users",
            Component: lazy(() => import("../pages/user/Users")),
            permissions: [100, 2000],

            auth: true,
            exact: true,
            menu: true,
          },
          {
            path: "/users/add",
            title: "Kullanıcı Ekle",
            title_index: "title_add_user",
            Component: lazy(() => import("../pages/user/AddUser")),
            permissions: [100, 2002],

            auth: true,
            exact: true,
            menu: true,
          },
          {
            path: "/users/edit/:id",
            title: "Kullanıcı Düzenle",
            title_index: "title_edit_user",
            Component: lazy(() => import("../pages/user/EditUser")),
            permissions: [100, 2001, 2003],

            auth: true,
            exact: true,
            menu: false,
          },
          {
            path: "/user-roles",
            title: "Kullanıcı Rolleri",
            title_index: "title_user_roles",
            Component: lazy(() => import("../pages/userRole/UserRoles")),
            permissions: [100, 2100],

            auth: true,
            exact: true,
            menu: true,
          },
          {
            path: "/user-roles/add",
            title: "Kullanıcı Rolü Ekle",
            title_index: "title_add_user_role",
            Component: lazy(() => import("../pages/userRole/AddUserRole")),
            permissions: [100, 2102],

            auth: true,
            exact: true,
            menu: true,
          },
          {
            path: "/user-roles/edit/:id",
            title: "Kullanıcı Rolü Düzenle",
            title_index: "title_edit_user_role",
            Component: lazy(() => import("../pages/userRole/EditUserRole")),
            permissions: [100, 2101, 2103],

            auth: true,
            exact: true,
            menu: false,
          },
          {
            path: "/user-feedbacks",
            title: "Kullanıcı Geri Bildirimleri",
            title_index: "title_user_feedbacks",
            Component: lazy(() =>
              import("../pages/userFeedback/UserFeedbacks")
            ),
            permissions: [100, 2800],
            auth: true,
            exact: true,
            menu: true,
          },
          {
            path: "/user-feedbacks/edit/:id",
            title: "Kullanıcı Geri Bildirim Düzenle",
            title_index: "title_edit_user_feedback",
            Component: lazy(() =>
              import("../pages/userFeedback/EditUserFeedback")
            ),
            permissions: [100, 2801],
            auth: true,
            exact: true,
            menu: false,
          },
          {
            path: "/user-questions",
            title: "Kullanıcı Soruları",
            title_index: "title_user_questions",
            Component: lazy(() =>
              import("../pages/userQuestions/UserQuestions")
            ),
            permissions: [],
            auth: true,
            exact: true,
            menu: true,
          },
          {
            path: "/user-questions/edit/:id",
            title: "Soru Düzenle",
            title_index: "title_user_questions_edit",
            Component: lazy(() =>
              import("../pages/userQuestions/UserQuestionsEdit")
            ),
            permissions: [],
            auth: true,
            exact: true,
            menu: false,
          },
        ],
      },
      {
        path: "#",
        title: "Banka Hesapları",
        title_index: "title_bank_accounts",
        permissions: [100, 1400, 1401, 1402, 1403, 1404],
        auth: true,
        exact: true,
        menu: true,
        children: [
          {
            path: "/bank-accounts",
            title: "Banka Hesapları",
            title_index: "title_bank_accounts",
            Component: lazy(() => import("../pages/bankAccount/BankAccounts")),
            permissions: [100, 1400],
            auth: true,
            exact: true,
            menu: true,
          },
          {
            path: "/bank-accounts/add",
            title: "Banka Hesabı Ekle",
            title_index: "title_add_bank_account",
            Component: lazy(() =>
              import("../pages/bankAccount/AddBankAccount")
            ),
            permissions: [100, 1402],
            auth: true,
            exact: true,
            menu: true,
          },
          {
            path: "/bank-accounts/edit/:id",
            title: "Banka Hesabı Düzenle",
            title_index: "title_edit_bank_account",
            Component: lazy(() =>
              import("../pages/bankAccount/EditBankAccount")
            ),
            permissions: [100, 1401, 1403],
            auth: true,
            exact: true,
            menu: false,
          },
        ],
      },
      {
        path: "#",
        title: "Siparişler",
        title_index: "title_orders",
        permissions: [100, 1500, 1501, 1502, 1503, 1504],
        auth: true,
        exact: true,
        menu: true,
        children: [
          {
            path: "/orders",
            title: "Siparişler",
            title_index: "title_orders",
            Component: lazy(() => import("../pages/order/Orders")),
            permissions: [100, 1500],
            auth: true,
            exact: true,
            menu: true,
          },
          {
            path: "/orders/edit/:id",
            title: "Sipariş Görüntüle",
            title_index: "title_show_order",
            Component: lazy(() => import("../pages/order/EditOrder")),
            permissions: [100, 1501],
            auth: true,
            exact: true,
            menu: false,
          },
          {
            path: "/orders/money-transfers",
            title: "Havale Bilgileri",
            title_index: "title_money_transfer_infos",
            Component: lazy(() =>
              import("../pages/orderMoneyTransfer/orderMoneyTransfers")
            ),
            permissions: [100, 1500],
            auth: true,
            exact: true,
            menu: true,
          },

          {
            path: "/order-refunds",
            title: "İade Siparişler",
            title_index: "title_order_refunds",
            Component: lazy(() => import("../pages/orderRefund/OrderRefunds")),
            permissions: [100, 1600],
            auth: true,
            exact: true,
            menu: true,
          },
          {
            path: "/order-refunds/edit/:id",
            title: "İade Sipariş Görüntüle",
            title_index: "title_show_order_refund",
            Component: lazy(() =>
              import("../pages/orderRefund/EditOrderRefund")
            ),
            permissions: [100, 1601],
            auth: true,
            exact: true,
            menu: false,
          },
          {
            path: "/order-refund-reasons",
            title: "İade Sipariş Sebepleri",
            title_index: "title_order_refund_reasons",
            Component: lazy(() =>
              import("../pages/orderRefundReason/OrderRefundReasons")
            ),
            permissions: [100, 1700],
            auth: true,
            exact: true,
            menu: true,
          },
          {
            path: "/order-refund-reasons/add",
            title: "İade Sipariş Sebep Ekle",
            title_index: "title_add_order_refund_reason",
            Component: lazy(() =>
              import("../pages/orderRefundReason/AddOrderRefundReason")
            ),
            permissions: [100, 1702],
            auth: true,
            exact: true,
            menu: true,
          },
          {
            path: "/order-refund-reasons/edit/:id",
            title: "İade Sipariş Sebep Düzenle",
            title_index: "title_edit_order_refund_reason",
            Component: lazy(() =>
              import("../pages/orderRefundReason/EditOrderRefundReason")
            ),
            permissions: [100, 1701, 1703],
            auth: true,
            exact: true,
            menu: false,
          },
          // {
          //   path: "/marketplace-orders",
          //   title: "Pazaryeri Siparişleri",
          //   title_index: "title_marketplace_orders",
          //   Component: lazy(() => import("../pages/order/MarketplaceOrders")),
          //   permissions: [100, 1500],
          //   auth: true,
          //   exact: true,
          //   menu: true,
          // },
        ],
      },
      {
        path: "#",
        title: "Bölgeler",
        title_index: "title_zones",
        permissions: [100, 2200, 2201, 2202, 2203, 2204],

        auth: true,
        exact: true,
        menu: true,
        children: [
          {
            path: "/zones",
            title: "Bölgeler",
            title_index: "title_zones",
            Component: lazy(() => import("../pages/zone/Zones")),
            permissions: [100, 2200],

            auth: true,
            exact: true,
            menu: true,
          },
          {
            path: "/zones/add",
            title: "Bölge Ekle",
            title_index: "title_add_zone",
            Component: lazy(() => import("../pages/zone/AddZone")),
            permissions: [100, 2202],

            auth: true,
            exact: true,
            menu: true,
          },
          {
            path: "/zones/edit/:id",
            title: "Bölge Düzenle",
            title_index: "title_edit_zone",
            Component: lazy(() => import("../pages/zone/EditZone")),
            permissions: [100, 2201, 2203],

            auth: true,
            exact: true,
            menu: false,
          },
        ],
      },
      {
        path: "/currency-exchange-rates",
        title: "Para Birimi Kurları",
        title_index: "title_currency_exchange_rates",
        Component: lazy(() =>
          import("../pages/currencyExchangeRate/CurrencyExchangeRates")
        ),
        permissions: [100, 3000, 3001, 3002, 3003, 3004],
        auth: true,
        exact: true,
        menu: true,
      },
      {
        path: "/files",
        title: "Dosyalar",
        title_index: "title_files",
        Component: lazy(() => import("../pages/file/Files")),
        permissions: [100, 2700, 2701, 2702, 2703, 2704],
        auth: true,
        exact: true,
        menu: true,
        children: [],
      },
      {
        path: "#",
        title: "Menüler",
        title_index: "title_menus",
        permissions: [100, 400, 2300, 2301, 2302, 2303, 2304],
        bordered: true,
        first: true,
        auth: true,
        exact: true,
        menu: true,
        children: [
          {
            path: "/menus",
            title: "Menüler",
            title_index: "title_menus",
            Component: lazy(() => import("../pages/menu/Menu")),
            permissions: [100, 2300, 2301, 2302, 2303, 2304],
            bordered: false,
            first: false,
            auth: true,
            exact: true,
            menu: true,
          },
          {
            path: "/menus/add",
            title: "Menü Ekle",
            title_index: "title_add_menu",
            Component: lazy(() => import("../pages/menu/AddMenu")),
            permissions: [100, 400, 2300, 2301, 2302, 2303, 2304],
            bordered: false,
            first: true,
            auth: true,
            exact: true,
            menu: true,
          },
          {
            path: "/menus/edit/:id",
            title: "Menü Düzenle",
            title_index: "title_edit_menu",
            Component: lazy(() => import("../pages/menu/EditMenu")),
            permissions: [100, 400, 2300, 2301, 2302, 2303, 2304],
            bordered: false,
            first: true,
            auth: true,
            exact: true,
            menu: false,
          },
          {
            path: "menu-designs",
            title: "Menü Dizayn",
            title_index: "title_menu_designs",
            Component: lazy(() => import("../pages/menu/MenuDesignList")),
            permissions: [],
            bordered: false,
            first: true,
            auth: true,
            exact: true,
            menu: true,
          },
          {
            path: "menu-designs/:id",
            title: "Menü Dizayn Düzenle",
            title_index: "title_design_menu",
            Component: lazy(() => import("../pages/menu/MenuDesignEdit")),
            permissions: [],
            bordered: false,
            first: true,
            auth: true,
            exact: true,
            menu: false,
          },
        ],
      },
      {
        path: "#",
        title: "Sayfalar",
        title_index: "title_pages",
        permissions: [100, 2300, 2301, 2302, 2303, 2304],
        bordered: true,
        auth: true,
        exact: true,
        menu: true,
        children: [
          {
            path: "/pages",
            title: "Sayfalar",
            title_index: "title_pages",
            Component: lazy(() => import("../pages/page/Pages")),
            permissions: [100, 2300],
            auth: true,
            exact: true,
            menu: true,
          },
          {
            path: "/pages/add",
            title: "Sayfa Ekle",
            title_index: "title_add_page",
            Component: lazy(() => import("../pages/page/AddPage")),
            permissions: [100, 2301, 2302],
            auth: true,
            exact: true,
            menu: true,
          },
          {
            path: "/pages/edit/:id",
            title: "Sayfa Düzenle",
            title_index: "title_edit_page",
            Component: lazy(() => import("../pages/page/EditPage")),
            permissions: [100, 2301, 2303],
            auth: true,
            exact: true,
            menu: false,
          },
        ],
      },
      {
        path: "/contracts",
        title: "Sözleşmeler",
        title_index: "title_contracts",
        Component: lazy(() => import("../pages/contracts/Contracts")),
        permissions: [],
        bordered: true,
        auth: true,
        exact: true,
        menu: true,
      },
      {
        path: "/contracts/edit/:id/:type",
        title: "Sözleşme Düzenle",
        title_index: "title_edit_contract",
        Component: lazy(() => import("../pages/contracts/EditContract")),
        permissions: [],
        auth: true,
        exact: true,
        menu: false,
      },
      {
        path: "/reports",
        title: "Raporlar",
        title_index: "title_reports",
        Component: lazy(() => import("../pages/reports/reports")),
        permissions: [],
        auth: true,
        exact: true,
        menu: true,
      },
      {
        path: "/newsletter",
        title: "E-Bülten",
        title_index: "title_newsletter",
        Component: lazy(() => import("../pages/newsletter/Newsletter")),
        permissions: [],
        auth: true,
        exact: true,
        menu: true,
      },
      {
        path: "/accounts",
        title: "Ayarlar",
        title_index: "title_settings",
        Component: lazy(() => import("../pages/auth/MyAccount")),

        permissions: [],
        auth: true,
        exact: true,
        menu: true,
      },
    ],
  },
  {
    path: "/modules",
    title: "Modüller",
    title_index: "title_modules",
    Component: lazy(() => import("../pages/Modules")),
    permissions: [100, 2600, 2601, 2602, 2603, 2604],

    auth: true,
    exact: true,
    menu: true,
    icon: <PuzzleIcon />,
  },
  // {
  //   path: "#",
  //   title: "Ülkeler",
  //   title_index: null,
  //   permissions: [1700],
  //   auth: true,
  //   exact: true,
  //   menu: false,
  // },
  {
    path: "/shop-countries",
    title: "Ülkeler",
    title_index: "title_shop_countries",
    Component: lazy(() => import("../pages/shopCountry/ShopCountries")),
    permissions: [100, 2600, 2601, 2602, 2603, 2604],

    auth: true,
    exact: true,
    menu: false,
  },
  {
    path: "/shop-countries/edit/:id",
    title: "Ülke Düzenle",
    title_index: "title_edit_shop_country",
    Component: lazy(() => import("../pages/shopCountry/EditShopCountry")),
    permissions: [100, 2600, 2601, 2602, 2603, 2604],

    auth: true,
    exact: true,
    menu: false,
  },
  {
    path: "/shop-languages",
    title: "Diller",
    title_index: "title_shop_languages",
    Component: lazy(() => import("../pages/shopLanguage/ShopLanguages")),
    permissions: [100, 2600, 2601, 2602, 2603, 2604],

    auth: true,
    exact: true,
    menu: false,
  },
  {
    path: "/shop-languages/edit/:id",
    title: "Dil Düzenle",
    title_index: "title_edit_shop_language",
    Component: lazy(() => import("../pages/shopLanguage/EditShopLanguage")),
    permissions: [100, 2600, 2601, 2602, 2603, 2604],

    auth: true,
    exact: true,
    menu: false,
  },
  {
    path: "/shop-currencies",
    title: "Para Birimleri",
    title_index: "title_shop_currencies",
    Component: lazy(() => import("../pages/shopCurrency/ShopCurrencies")),
    permissions: [100, 2600, 2601, 2602, 2603, 2604],

    auth: true,
    exact: true,
    menu: false,
  },
  {
    path: "/shop-currencies/edit/:id",
    title: "Para Birimi Düzenle",
    title_index: "title_edit_shop_currency",
    Component: lazy(() => import("../pages/shopCurrency/EditShopCurrency")),
    permissions: [100, 2600, 2601, 2602, 2603, 2604],

    auth: true,
    exact: true,
    menu: false,
  },
  // {
  //   path: "#",
  //   title: "Ödeme Entegrasyonları",
  //   title_index: "Anasayfa",
  //   permissions: [],
  //   auth: true,
  //   exact: true,
  //   menu: false,
  // },
  // {
  //   path: "#",
  //   title: "Muhasebe Entegrasyonları",
  //   title_index: "Anasayfa",
  //   permissions: [],
  //   auth: true,
  //   exact: true,
  //   menu: false,
  // },
  {
    path: "/shop-invoice-methods",
    title: "Kargo Entegrasyonları",
    title_index: "title_shop_invoice_methods",
    Component: lazy(() =>
      import("../pages/shopInvoiceMethod/ShopInvoiceMethods")
    ),
    permissions: [100, 2600, 2601, 2602, 2603, 2604],

    auth: true,
    exact: true,
    menu: false,
  },
  {
    path: "/shop-invoice-methods/edit/:id",
    title: "Kargo Entegrasyonu Düzenle",
    title_index: "title_edit_shop_invoice_method",
    Component: lazy(() =>
      import("../pages/shopInvoiceMethod/EditShopInvoiceMethod")
    ),
    permissions: [100, 2600, 2601, 2602, 2603, 2604],

    auth: true,
    exact: true,
    menu: false,
  },
  {
    path: "/shop-shipping-methods",
    title: "Kargo Entegrasyonları",
    title_index: "title_shop_shipping_methods",
    Component: lazy(() =>
      import("../pages/shopShippingMethod/ShopShippingMethods")
    ),
    permissions: [100, 2600, 2601, 2602, 2603, 2604],

    auth: true,
    exact: true,
    menu: false,
  },
  {
    path: "/shop-shipping-methods/edit/:id",
    title: "Kargo Entegrasyonu Düzenle",
    title_index: "title_edit_shop_shipping_method",
    Component: lazy(() =>
      import("../pages/shopShippingMethod/EditShopShippingMethod")
    ),
    permissions: [100, 2600, 2601, 2602, 2603, 2604],

    auth: true,
    exact: true,
    menu: false,
  },
  {
    path: "/shop-phone-message-methods",
    title: "SMS Entegrasyonları",
    title_index: "title_shop_phone_message_methods",
    Component: lazy(() =>
      import("../pages/shopPhoneMessageMethod/ShopPhoneMessageMethods")
    ),
    permissions: [],
    auth: true,
    exact: true,
    menu: false,
  },
  {
    path: "/shop-phone-message-methods/edit/:id",
    title: "SMS Entegrasyonu Düzenle",
    title_index: "title_edit_shop_phone_message_methods",
    Component: lazy(() =>
      import("../pages/shopPhoneMessageMethod/EditShopPhoneMessageMethod")
    ),
    permissions: [],

    auth: true,
    exact: true,
    menu: false,
  },
  {
    path: "/shop-payment-methods",
    title: "Ödeme Entegrasyonları",
    title_index: "title_shop_payment_methods",
    Component: lazy(() =>
      import("../pages/shopPaymentMethod/ShopPaymentMethods")
    ),
    permissions: [100, 2600, 2601, 2602, 2603, 2604],

    auth: true,
    exact: true,
    menu: false,
  },
  {
    path: "/shop-payment-methods/edit/:id",
    title: "Ödeme Entegrasyonu Düzenle",
    title_index: "title_edit_shop_payment_method",
    Component: lazy(() =>
      import("../pages/shopPaymentMethod/EditShopPaymentMethod")
    ),
    permissions: [100, 2600, 2601, 2602, 2603, 2604],

    auth: true,
    exact: true,
    menu: false,
  },
  {
    path: "/account",
    title: "Hesabım",
    title_index: "title_my_account",
    Component: lazy(() => import("../pages/auth/MyAccount")),
    permissions: [],
    auth: true,
    exact: true,
    menu: false,
  },
  {
    path: "/auth/login",
    title: "Giriş Yap",
    title_index: "title_auth_login",
    Component: lazy(() => import("../pages/auth/Login")),
    permissions: [],
    auth: false,
    exact: true,
    menu: false,
  },
  // {
  //   path: '/auth/register',
  //   title: 'Üye Ol',
  //   title_index: 'title_auth_register',
  //   Component: lazy(() => import('../pages/auth/Register')),
  //   permissions: [],
  //   auth: false,
  //   exact: true,
  //   menu: false,
  // },
  {
    path: "/auth/forget-password",
    title: "Şifremi Unuttum",
    title_index: "title_auth_forget_password",
    Component: lazy(() => import("../pages/auth/ForgetPassword")),
    permissions: [],
    auth: false,
    exact: true,
    menu: false,
  },
  {
    path: "/auth/reset-password",
    title: "Şifremi Yenile",
    title_index: "title_auth_reset_password",
    Component: lazy(() => import("../pages/auth/ResetPassword")),
    permissions: [],
    auth: false,
    exact: true,
    menu: false,
  },
  // {
  //   path: "/feedbacks",
  //   title: "Geri Bildirimler",
  //   title_index: "title_feedbacks",
  //   Component: lazy(() => import("../pages/feedback/FeedBacks")),
  //   permissions: [100, 2500],
  //   auth: true,
  //   exact: true,
  //   menu: false,
  // },
  // {
  //   path: "/feedbacks/add",
  //   title: "Geri Bildirim Ekle",
  //   title_index: "title_add_feedback",
  //   Component: lazy(() => import("../pages/feedback/AddFeedBack")),
  //   permissions: [100, 2502],

  //   auth: true,
  //   exact: true,
  //   menu: false,
  // },
  // {
  //   path: "/feedbacks/edit/:id",
  //   title: "Geri Bildirim Görüntüle",
  //   title_index: "title_edit_feedback",
  //   Component: lazy(() => import("../pages/feedback/EditFeedBack")),
  //   permissions: [100, 2501, 2503],

  //   auth: true,
  //   exact: true,
  //   menu: false,
  // },
  {
    path: "/changelog-detail/:id",
    title: "Güncelleme Detayı",
    title_index: "title_changelog_detail",
    Component: lazy(() => import("../pages/changelog/ChangelogDetail")),
    permissions: [],
    auth: true,
    exact: true,
    menu: false,
  },
  {
    path: "/404",
    title: "Sayfa Bulunamadı",
    title_index: "title_page_not_found",
    Component: lazy(() => import("../pages/NotFound")),
    permissions: [],
    auth: false,
    exact: false,
    menu: false,
  },
  // {
  //   path: "*",
  //   title: "Sayfa Bulunamadı",
  //   Component: lazy(() => import("../pages/NotFound")),
  //   permissions: [],
  //   auth: false,
  //   exact: false,
  //   menu: false,
  // },
  // {
  //   path: '/pagebuilder',
  //   title: 'Anasayfa Düzenle',
  //   title_index: 'title_dashboard',
  //   Component: lazy(() => import('../pages/pageBuilder/PageBuilder')),
  //   permissions: [100, 300, 301, 302, 303, 304],
  //   auth: true,
  //   exact: true,
  //   menu: false,
  //   icon: <HomeIcon />,
  // },
  {
    path: "/fastsale",
    title: "Fast Sale",
    title_index: "title_fast_sale",
    Component: lazy(() => import("../pages/fastSale/FastSale")),
    // permissions: [100, 300, 301, 302, 303, 304],
    permissions: [100, 2900],
    auth: true,
    exact: true,
    menu: true,
    icon: <CashIcon />,
  },
  {
    path: "/orders/edit/:order_id/basket-picking",
    title: "Order Basket Picking",
    title_index: "title_order_basket_picking",
    Component: lazy(() => import("../pages/order/OrderBasketPicking")),
    permissions: [],
    auth: true,
    exact: true,
    menu: false,
    icon: <CashIcon />,
  },
  // {
  //   path: "/offers",
  //   title: "Offers",
  //   title_index: "title_offers",
  //   Component: lazy(() => import("../pages/offers")),
  //   // permissions: [100, 300, 301, 302, 303, 304],
  //   permissions: [],
  //   auth: true,
  //   exact: true,
  //   menu: false,
  //   icon: <DiscountIcon />,
  // },
  {
    path: "/pre-accountancy",
    title: "Pre Accountancy",
    title_index: "title_pre_accountancy",
    Component: lazy(() => import("../pages/pre_accountancy")),
    // permissions: [100, 300, 301, 302, 303, 304],
    permissions: [],
    auth: true,
    exact: true,
    menu: false,
    icon: <BookIcon />,
  },
  {
    path: "/barcode/generator",
    title: "Barcode Generator",
    title_index: "title_barcode_generator",
    Component: lazy(() => import("../pages/barcode/generator")),

    // permissions: [100, 300, 301, 302, 303, 304],
    permissions: [100, 3100],
    auth: true,
    exact: true,
    menu: true,
    icon: <BarcodeIcon />,
  },
  {
    path: "/barcode/:id",
    title: "Barcode Generator",
    title_index: "title_barcode_generator",
    Component: lazy(() => import("../pages/barcode")),
    permissions: [100, 3100],
    permissions: [],
    auth: true,
    exact: true,
    menu: false,
  },
  {
    path: "/pagebuilder",
    title: "PageBuilder",
    title_index: "title_pagebuilder",
    Component: lazy(() => import("../pages/pageBuilder/ufc/ufc-pageBuilder")),
    // permissions: [100, 300, 301, 302, 303, 304],
    permissions: [100, 3200],
    auth: true,
    exact: true,
    menu: true,
    icon: <PageBuilderIcon />,
  },
  {
    path: "#",
    title: "Muhasebe",
    title_index: "title_accounting",
    permissions: [100],
    auth: true,
    exact: true,
    menu: true,
    icon: <FileInvoiceIcon />,
    children: [
      {
        path: "#",
        title: "Müşteriler",
        title_index: "title_accounting_contacts",

        permissions: [100],
        auth: true,
        exact: true,
        menu: true,
        children: [
          {
            path: "/accounting/contacts",
            title: "Müşteriler",
            title_index: "title_accounting_contacts",
            Component: lazy(() =>
              import("../pages/accountingContact/AccountingContacts")
            ),

            permissions: [100],
            auth: true,
            exact: true,
            menu: true,
          },
          {
            path: "/accounting/contacts/add",
            title: "Müşteri Ekle",
            title_index: "title_add_accounting_contact",
            Component: lazy(() =>
              import("../pages/accountingContact/AddAccountingContact")
            ),

            permissions: [100],
            auth: true,
            exact: true,
            menu: true,
          },
          {
            path: "/accounting/contacts/edit/:id",
            title: "Müşteri Düzenle",
            title_index: "title_edit_accounting_contact",
            Component: lazy(() =>
              import("../pages/accountingContact/EditAccountingContact")
            ),

            permissions: [100],
            auth: true,
            exact: true,
            menu: false,
          },
        ],
      },
      {
        path: "#",
        title: "Kasa ve Banka Hesapları",
        title_index: "title_accounting_accounts",
        permissions: [100],
        auth: true,
        exact: true,
        menu: true,
        children: [
          {
            path: "/accounting/accounts",
            title: "Kasa ve Banka Hesapları",
            title_index: "title_accounting_accounts",
            Component: lazy(() =>
              import("../pages/accountingAccount/AccountingAccounts")
            ),
            permissions: [100],
            auth: true,
            exact: true,
            menu: true,
          },
          {
            path: "/accounting/accounts/add",
            title: "Kasa ve Banka Hesabı Ekle",
            title_index: "title_add_accounting_account",
            Component: lazy(() =>
              import("../pages/accountingAccount/AddAccountingAccount")
            ),
            permissions: [100],
            auth: true,
            exact: true,
            menu: true,
          },
          {
            path: "/accounting/accounts/edit/:id",
            title: "Kasa ve Banka Hesabı Düzenle",
            title_index: "title_edit_accounting_account",
            Component: lazy(() =>
              import("../pages/accountingAccount/EditAccountingAccount")
            ),
            permissions: [100],
            auth: true,
            exact: true,
            menu: false,
          },
        ],
      },
      {
        path: "#",
        title: "Muhasebe",
        title_index: "title_accounting_transactions",
        permissions: [100],
        auth: true,
        exact: true,
        menu: true,
        children: [
          {
            path: "/accounting/invoices",
            title: "Faturalar",
            title_index: "title_accounting_transactions",
            Component: lazy(() =>
              import("../pages/accountingInvoice/AccountingInvoices")
            ),
            permissions: [100],
            auth: true,
            exact: true,
            menu: true,
          },
          {
            path: "/accounting/invoices/add",
            title: "Fatura Ekle",
            title_index: "title_add_accounting_transaction",
            Component: lazy(() =>
              import("../pages/accountingInvoice/AddAccountingInvoice")
            ),
            permissions: [100],
            auth: true,
            exact: true,
            menu: true,
          },
          {
            path: "/accounting/invoices/edit/:id",
            title: "Fatura Düzenle",
            title_index: "title_edit_accounting_transaction",
            Component: lazy(() =>
              import("../pages/accountingInvoice/EditAccountingInvoice")
            ),
            permissions: [100],
            auth: true,
            exact: true,
            menu: false,
          },
        ],
      },

      {
        path: "/accounting/cheques",
        title: "Çekler",
        title_index: "title_accounting_cheques",
        Component: lazy(() =>
          import("../pages/accountingCheque/AccountingCheques")
        ),
        permissions: [100],
        auth: true,
        exact: true,
        menu: true,
      },
      {
        path: "/accounting/cheques/edit/:id",
        title: "Çek Düzenle",
        title_index: "title_edit_accounting_cheque",
        Component: lazy(() =>
          import("../pages/accountingCheque/EditAccountingCheque")
        ),
        permissions: [100],
        auth: true,
        exact: true,
        menu: false,
      },
    ],
  },
  // {
  //   path: "/trendyol",
  //   title: "TrendyolDenemes",
  //   Component: lazy(() => import("../pages/trendyolDeneme/TrendyolDenemes")),
  // },
];

export default routes;
