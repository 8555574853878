// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ck-editor__editable {
  min-height: 400px;
}

.input-group input[type="number"]::-webkit-inner-spin-button,
.input-group input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input-group input[type="number"] {
  -moz-appearance: textfield;
}

.customshadow {
  box-shadow: none;
}
@media only screen and (max-width: 768px) {
  .customshadow {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }
}

.show:not(.offcanvas.offcanvas-start.show) {
  display: block !important;
}

.modal-overlay-new {
  background-color: rgba(0, 0, 0, 0.5);
}

.centeredDiv {
  margin: 0 auto;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */ /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.invoiceModal {
  max-width: 70%;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;;AAEA;;EAEE,wBAAwB;EACxB,SAAS;AACX;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,gBAAgB;AAClB;AACA;EACE;IACE,iDAAiD;EACnD;AACF;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,2BAA2B,EAAE,eAAe;EAC5C,yBAAyB,EAAE,WAAW,EACZ,mBAAmB,EACrB,4BAA4B,EAC7B,2BAA2B;EAClD,iBAAiB,EAAE;gFAC2D;AAChF;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".ck-editor__editable {\n  min-height: 400px;\n}\n\n.input-group input[type=\"number\"]::-webkit-inner-spin-button,\n.input-group input[type=\"number\"]::-webkit-outer-spin-button {\n  -webkit-appearance: none;\n  margin: 0;\n}\n\n.input-group input[type=\"number\"] {\n  -moz-appearance: textfield;\n}\n\n.customshadow {\n  box-shadow: none;\n}\n@media only screen and (max-width: 768px) {\n  .customshadow {\n    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;\n  }\n}\n\n.show:not(.offcanvas.offcanvas-start.show) {\n  display: block !important;\n}\n\n.modal-overlay-new {\n  background-color: rgba(0, 0, 0, 0.5);\n}\n\n.centeredDiv {\n  margin: 0 auto;\n}\n\n.noselect {\n  -webkit-touch-callout: none; /* iOS Safari */\n  -webkit-user-select: none; /* Safari */\n  -khtml-user-select: none; /* Konqueror HTML */\n  -moz-user-select: none; /* Old versions of Firefox */\n  -ms-user-select: none; /* Internet Explorer/Edge */\n  user-select: none; /* Non-prefixed version, currently\n                                  supported by Chrome, Edge, Opera and Firefox */\n}\n\n.invoiceModal {\n  max-width: 70%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
