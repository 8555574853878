import axios from "../utils/CustomAxios";
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  BellIcon,
  LanguageIcon,
  StarIcon,
  UserIcon,
  BrowserIcon,
  BrowserCheckIcon,
} from './Icons';
import { useDispatch, useSelector } from 'react-redux';
import { setUserData } from '../redux/slices/userSlice';
import NavBar from './NavBar';
import Avatar from './Avatar';

const expectRoutes = [
  '/404',
  '/auth/login',
  '/auth/register',
  '/auth/forget-password',
  '/auth/reset-password',
  '/shops/add'
];

export default function Header({ userData }) {
  const dispatch = useDispatch();
  const { domain } = useSelector((state) => state.shop.shopDetail);
  const { pathname } = useLocation();
  const { t } = useTranslation('common');
  const [visibleIndex, setVisibleIndex] = useState(0);
  const navbarRef = useRef(null); // Ref for the navbar container element

  const changeVisibleIndex = (index) => {
    setVisibleIndex(index === visibleIndex ? 0 : index);
  };

  useEffect(() => {
    // Function to handle click outside the navbar
    const handleClickOutside = (event) => {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        setVisibleIndex(0);
      }
    };

    // Add event listener to handle click outside the navbar
    document.addEventListener('click', handleClickOutside);

    return () => {
      // Cleanup: remove event listener when component unmounts
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  // '/auth/reset-password/:resetToken' için regex deseni
  const resetPasswordRegex = /^\/auth\/reset-password\/.+$/;

  if (expectRoutes.includes(pathname) || resetPasswordRegex.test(pathname)) {
    // Menüyü gizle
    return null;
  }
  return (
    <>
      <header className="navbar navbar-expand-md navbar-light d-print-none">
        <div className="container-xl">
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbar-menu"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <h1 className="navbar-brand navbar-brand-autodark d-none-navbar-horizontal pe-0 pe-md-3">
            <Link to="/">
              <img src="/dist/img/logo.png" width="90" height="20" alt="shopiget-logo" />
            </Link>
          </h1>
          <div className="navbar-nav flex-row order-md-last" ref={navbarRef}>
            {/* WEB */}
            <div className="d-none d-md-flex">
              <div className="nav-item dropdown d-none d-md-flex me-2">
                <a
                  className="nav-link px-0 cursor-pointer"
                  href={`https://${domain}`}
                  // href={`${domain === "web.shopiget.com" ? "https://web.shopiget.com" : "http://sifremiunuttum.shopiget.com"}`}
                  target="_blank"
                // rel='noreferrer'
                >
                  {/* Website */}
                  <Avatar className="avatar-sm bg-dark-lt text-white">
                    {/* <BrowserIcon /> */}
                    <BrowserCheckIcon />
                  </Avatar>
                </a>
              </div>
            </div>
            {/* WEB */}
            <div className="d-none d-md-flex">
              <div className="nav-item dropdown d-none d-md-flex me-2">
                <span
                  className="nav-link px-0 cursor-pointer"
                  onClick={() => changeVisibleIndex(4)}
                >
                  {/* <LanguageIcon /> */}
                  <Avatar className="avatar-sm bg-dark-lt text-white">
                    <LanguageIcon />
                  </Avatar>
                </span>
                {visibleIndex === 4 && <LanguagesMenu dispatch={dispatch} setVisibleIndex={setVisibleIndex} />}
              </div>
              <div className="nav-item dropdown d-none d-md-flex me-2">
                <span
                  className="nav-link px-0 cursor-pointer"
                  onClick={() => changeVisibleIndex(1)}
                >
                  {/* <BellIcon /> */}
                  <Avatar className="avatar-sm bg-dark-lt text-white">
                    <BellIcon />
                    {/* <span className="badge bg-primary"></span> */}
                  </Avatar>
                </span>
                {visibleIndex === 1 && <NotificationMenu />}
              </div>
            </div>
            <div className="nav-item dropdown">
              <span
                className="nav-link d-flex lh-1 text-reset p-0 cursor-pointer"
                onClick={() => changeVisibleIndex(3)}
              >
                <Avatar className="avatar-sm bg-dark-lt text-white">
                  <UserIcon />
                </Avatar>
                <div className="d-none d-xl-block ps-2">
                  <div className="text-uppercase">{userData.data?.name + ' ' + userData.data?.surname}</div>
                  <div className="mt-1 small text-muted">{t('my_account')}</div>
                </div>
              </span>
              {visibleIndex === 3 && <AccountMenu setVisibleIndex={setVisibleIndex} dispatch={dispatch} />}
            </div>
          </div>
        </div>
      </header>

      <NavBar userData={userData} />
    </>
  );
}


const LanguagesMenu = ({ setVisibleIndex }) => {
  const { i18n } = useTranslation('common');
  const [languages, setLanguages] = useState([]);

  useEffect(() => {
    const fetchLanguages = async () => {
      try {
        const response = await axios.get("v1/panel/shop-modules/languages-for-header", { params: { type: "language" } });
        if (response.data.status === 1) {
          setLanguages(response.data.data);
        }
      } catch (error) {
        console.error('Error fetching languages:', error);
      }
    };
    fetchLanguages();
  }, []);


  const changeLanguage = (item) => {
    // dispatch(setLocalizationData({ language: item }));
    localStorage.setItem('language_id', item.id);
    localStorage.setItem('lang_id', item.language_id);
    i18n.changeLanguage(item.code);
    setVisibleIndex(0);
  };

  return (
    <div
      className="dropdown-menu dropdown-menu-arrow dropdown-menu-end dropdown-menu-card show"
      data-bs-popper="none">
      <div className="card">
        <div className="card-header">
          <h3 className="card-title">Diller</h3>
        </div>
        <div className="list-group list-group-flush list-group-hoverable">
          {languages.map((item, key) => {
            return (
              <div key={key} className="list-group-item cursor-pointer"
                onClick={() => changeLanguage(item)}>
                <div className="row align-items-center">
                  <div className="col text-truncate">
                    <span className="text-body d-block">{item.name}</span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const NotificationMenu = () => {
  const notifications = [];

  return (
    <div
      className="dropdown-menu dropdown-menu-arrow dropdown-menu-end dropdown-menu-card show"
      data-bs-popper="none">
      <div className="card">
        <div className="card-header">
          <h3 className="card-title">Bildirimler</h3>
        </div>
        <div className="list-group list-group-flush list-group-hoverable">
          {notifications.length > 0 ? (
            notifications.map((item, key) => {
              return (
                <div className="list-group-item" key={key}>
                  <div className="row align-items-center">
                    <div className="col-auto">
                      <span
                        className="status-dot status-dot-animated bg-red d-block"></span>
                    </div>
                    <div className="col text-truncate">
                      <a href="#" className="text-body d-block">
                        Example 1
                      </a>
                      <div
                        className="d-block text-muted text-truncate mt-n1">
                        Change deprecated html tags to text decoration
                        classes (#29604)
                      </div>
                    </div>
                    <div className="col-auto">
                      <span className="list-group-item-actions show">
                        <StarIcon className="text-yellow" />
                      </span>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="list-group-item">
              <div className="row align-items-center">
                <div className="col text-truncate">Görüntülenecek bildirim
                  yok.
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const AccountMenu = ({ setVisibleIndex, dispatch }) => {
  const { t } = useTranslation('common');
  const navigate = useNavigate();

  const logout = () => {
    setVisibleIndex(0);
    localStorage.removeItem('token');
    localStorage.removeItem('shop_id');
    dispatch(
      setUserData({ isLoad: false, isLogin: false, data: { permissions: [] } }));
    navigate('/auth/login');
  };

  return (
    <div className="dropdown-menu dropdown-menu-end dropdown-menu-arrow show"
      data-bs-popper="none">
      <Link to="/account" className="dropdown-item"
        onClick={() => setVisibleIndex(0)}>
        {t('my_account')}
      </Link>
      <div className="dropdown-divider"></div>
      <Link to="/feedbacks" className="dropdown-item"
        onClick={() => setVisibleIndex(0)}>
        {t('title_feedbacks')}
      </Link>
      <span onClick={logout} className="dropdown-item">
        {t('logout')}
      </span>
    </div>
  );
};
