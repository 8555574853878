import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { Modal } from "../Modal";
import axios from "../../utils/CustomAxios";
import Tabs, { Tab } from "../Tabs";
import Button from "../Button";
import TaxAPI from "../../api/TaxAPI";
import { errorToast, successToast } from "../../utils/toastNotification";

export default function TaxForm({
  visible,
  setVisible,
  onSuccess,
  pageComponent = false,
  defaultFormik = null,
}) {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();

  const [rates, setRates] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [loading, setLoading] = useState(false);

  const validationSchema = Yup.object({
    name: Yup.string().required(t("validation.product_tax_name")),
    description: Yup.string().required(t("validation.product_tax_description")),
  });

  const formik = defaultFormik
    ? defaultFormik
    : useFormik({
        initialValues: {
          name: "",
          description: "",
          status: true,
          rates: rates,
        },

        validationSchema,
        onSubmit: (model) => {
          const updatedRates = rates.map((item, index) => ({
            ...item,
            rate: formik.values.rates[index]?.rate || item.rate,
          }));
          setRates(updatedRates);
          model.rates = updatedRates;

          addProductTax(model);
        },
      });

  const tabs = [
    {
      name: t("general_information"),
    },
    { name: "Vergi Oranları" },
  ];

  const getCountries = () => {
    axios
      .get("v1/panel/countries", { params: { type: "available" } })
      .then((res) => {
        if (res.data.status === 1) {
          setRates(
            res.data.data.map((x) => {
              return {
                id: 0,
                country_id: x.id,
                country_name: x.name,
                rate: "",
              };
            })
          );
        }
      });
  };

  const addProductTax = (model) => {
    setLoading(true);

    if (model.rates.filter((x) => typeof x.rate === "number").length > 0) {
      TaxAPI.create(model)
        .then((res) => {
          if (res.data.status === 1) {
            successToast(t, "saved");
            formik.resetForm();
            setTabIndex(0);
            onSuccess();
          }
          setLoading(false);
        })
        .catch((err) => {
          errorToast(t, "notSaved");
          setLoading(false);
        });
    } else {
      setLoading(false);
      setIsModalOpen(true);
    }
  };

  useEffect(() => {
    getCountries();
  }, [dispatch]);

  if (pageComponent)
    return (
      <FormContent
        tabIndex={tabIndex}
        setTabIndex={setTabIndex}
        tabs={tabs}
        formik={formik}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        rates={rates}
      />
    );

  return (
    <Modal
      modalStyle={{ zIndex: "100" }}
      title={t("title_add_tax")}
      visible={visible}
      setVisible={setVisible}
    >
      <div>
        <div className="btn-list row w-100 justify-content-end p-0 m-0 mb-3">
          {tabIndex === tabs.length - 1 && (
            <Button
              text={t("save")}
              className="btn-outline-primary col-2 m-0"
              onClick={formik.submitForm}
            />
          )}
        </div>
        {/* <form onSubmit={formik.handleSubmit}>
          {isModalOpen && (
            <div
              className="modal modal-blur fade show"
              id="modal-small"
              tabIndex={1}
              style={{ display: "block" }}
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-sm modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-body">
                    <div className="modal-title">{t("warning")}</div>
                    <div>{t("validation.manuel.taxRate")}</div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-info"
                      onClick={() => setIsModalOpen(false)}
                    >
                      {t("ok")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          <Tabs
            onChange={(index) => setTabIndex(index)}
            changeTabActive={true}
            outsideTabIndex={tabIndex}
            isTabClickable={false}
            tabs={tabs}
          >
            <Tab>
              <div className="row">
                <div className="col-12 mb-3">
                  <div className="form-group row">
                    <label className="form-label col-3 col-form-label required">
                      {t("labels.name")}
                    </label>
                    <div className="col">
                      <input
                        type="text"
                        className={`form-control ${
                          formik.touched.name
                            ? formik.errors.name
                              ? "is-invalid is-invalid-lite"
                              : "is-valid is-valid-lite"
                            : ""
                        }`}
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        id="name"
                        name="name"
                      />
                      {formik.touched.name && formik.errors.name ? (
                        <div className="invalid-feedback">
                          {formik.errors.name}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="col-12 mb-3">
                  <div className="form-group row">
                    <label className="form-label col-3 col-form-label required">
                      {t("labels.description")}
                    </label>
                    <div className="col">
                      <input
                        type="text"
                        className={`form-control ${
                          formik.touched.description
                            ? formik.errors.description
                              ? "is-invalid is-invalid-lite"
                              : "is-valid is-valid-lite"
                            : ""
                        }`}
                        value={formik.values.description}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        id="description"
                        name="description"
                      />
                      {formik.touched.description &&
                      formik.errors.description ? (
                        <div className="invalid-feedback">
                          {formik.errors.description}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="col-12">
                  <div className="form-group row">
                    <label className="form-label col-3 col-form-label required">
                      {t("labels.status")}
                    </label>
                    <div className="col d-flex align-items-center">
                      <label className="form-check form-switch mb-0">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="status"
                          checked={formik.values.status}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex gap-2 justify-content-end w-100 mt-3">
                {tabIndex > 0 && (
                  <Button
                    className="btn btn-ghost-primary py-1 px-3"
                    onClick={() => {
                      if (tabIndex > 0) setTabIndex(tabIndex - 1);
                    }}
                    text="Geri Dön"
                  />
                )}
                {tabIndex < tabs.length - 1 && (
                  <>
                    {formik.errors.name ||
                    formik.errors.description ||
                    formik.values.name === "" ||
                    formik.values.description === "" ? (
                      <Button
                        style={{
                          cursor: "not-allowed",
                          opacity: "50%",
                        }}
                        className="btn btn-ghost-primary  py-1 px-3"
                        disabled={true}
                        text="Devam Et"
                        onClick={(e) => e.preventDefault()}
                      />
                    ) : (
                      <Button
                        className="btn btn-ghost-primary py-1 px-3"
                        onClick={(e) => {
                          e.preventDefault();
                          if (tabIndex < tabs.length - 1)
                            setTabIndex(tabIndex + 1);
                        }}
                        text="Devam Et"
                      />
                    )}
                  </>
                )}
              </div>
            </Tab>
            <Tab>
              <div className="row">
                {rates.map((item, key) => {
                  return (
                    <div className="col-12 mb-3" key={key}>
                      <div className="form-group row">
                        <label className="form-label col-3 col-form-label">
                          {item.country_name}
                        </label>
                        <div className="col d-flex align-items-center">
                          <div className="input-group">
                            <span className="input-group-text">%</span>
                            <input
                              className="form-control"
                              type="number"
                              min={0}
                              max={100}
                              placeholder={`${item.country_name} ülkesi için vergi oranını giriniz.`}
                              name={`rates[${key}].rate`}
                              value={formik.values.rates?.[key]?.rate}
                              onChange={formik.handleChange}
                              onBlur={(event) => {
                                let newValue = parseInt(event.target.value);
                                newValue = isNaN(newValue)
                                  ? 0
                                  : newValue < 0
                                  ? 0
                                  : Math.min(newValue, 100);
                                formik.setFieldValue(
                                  `rates[${key}].rate`,
                                  newValue
                                );
                                formik.handleBlur(event);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="d-flex gap-2 justify-content-end w-100 mt-3">
                {tabIndex > 0 && (
                  <Button
                    className="btn btn-ghost-primary py-1 px-3"
                    onClick={(e) => {
                      e.preventDefault();
                      if (tabIndex > 0) setTabIndex(tabIndex - 1);
                    }}
                    text="Geri Dön"
                  />
                )}
                {tabIndex < tabs.length - 1 && (
                  <Button
                    className="btn btn-ghost-primary py-1 px-3"
                    onClick={(e) => {
                      e.preventDefault();
                      if (tabIndex < tabs.length - 1) setTabIndex(tabIndex + 1);
                    }}
                    text="Devam Et"
                  />
                )}
              </div>
            </Tab>
          </Tabs>
        </form> */}
        <FormContent
          tabIndex={tabIndex}
          setTabIndex={setTabIndex}
          tabs={tabs}
          formik={formik}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          rates={rates}
        />
      </div>
    </Modal>
  );
}

const FormContent = ({
  tabIndex,
  setTabIndex,
  tabs,
  formik,
  isModalOpen,
  setIsModalOpen,
  rates,
}) => {
  const { t } = useTranslation("common");

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        {isModalOpen && (
          <div
            className="modal modal-blur fade show"
            id="modal-small"
            tabIndex={1}
            style={{ display: "block" }}
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-sm modal-dialog-centered"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-body">
                  <div className="modal-title">{t("warning")}</div>
                  <div>{t("validation.manuel.taxRate")}</div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-info"
                    onClick={() => setIsModalOpen(false)}
                  >
                    {t("ok")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        <Tabs
          onChange={(index) => setTabIndex(index)}
          changeTabActive={true}
          outsideTabIndex={tabIndex}
          isTabClickable={false}
          tabs={tabs}
        >
          <Tab>
            <div className="row">
              <div className="col-12 mb-3">
                <div className="form-group row">
                  <label className="form-label col-3 col-form-label required">
                    {t("labels.name")}
                  </label>
                  <div className="col">
                    <input
                      type="text"
                      className={`form-control ${
                        formik.touched.name
                          ? formik.errors.name
                            ? "is-invalid is-invalid-lite"
                            : "is-valid is-valid-lite"
                          : ""
                      }`}
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      id="name"
                      name="name"
                    />
                    {formik.touched.name && formik.errors.name ? (
                      <div className="invalid-feedback">
                        {formik.errors.name}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="col-12 mb-3">
                <div className="form-group row">
                  <label className="form-label col-3 col-form-label required">
                    {t("labels.description")}
                  </label>
                  <div className="col">
                    <input
                      type="text"
                      className={`form-control ${
                        formik.touched.description
                          ? formik.errors.description
                            ? "is-invalid is-invalid-lite"
                            : "is-valid is-valid-lite"
                          : ""
                      }`}
                      value={formik.values.description}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      id="description"
                      name="description"
                    />
                    {formik.touched.description && formik.errors.description ? (
                      <div className="invalid-feedback">
                        {formik.errors.description}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="col-12">
                <div className="form-group row">
                  <label className="form-label col-3 col-form-label required">
                    {t("labels.status")}
                  </label>
                  <div className="col d-flex align-items-center">
                    <label className="form-check form-switch mb-0">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="status"
                        checked={formik.values.status}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex gap-2 justify-content-end w-100 mt-3">
              {tabIndex > 0 && (
                <Button
                  className="btn btn-ghost-primary py-1 px-3"
                  onClick={() => {
                    if (tabIndex > 0) setTabIndex(tabIndex - 1);
                  }}
                  text="Geri Dön"
                />
              )}
              {tabIndex < tabs.length - 1 && (
                <>
                  {formik.errors.name ||
                  formik.errors.description ||
                  formik.values.name === "" ||
                  formik.values.description === "" ? (
                    <Button
                      style={{
                        cursor: "not-allowed",
                        opacity: "50%",
                      }}
                      className="btn btn-ghost-primary  py-1 px-3"
                      disabled={true}
                      text="Devam Et"
                      onClick={(e) => e.preventDefault()}
                    />
                  ) : (
                    <Button
                      className="btn btn-ghost-primary py-1 px-3"
                      onClick={(e) => {
                        e.preventDefault();
                        if (tabIndex < tabs.length - 1)
                          setTabIndex(tabIndex + 1);
                      }}
                      text="Devam Et"
                    />
                  )}
                </>
              )}
            </div>
          </Tab>
          <Tab>
            <div className="row">
              {rates.map((item, key) => {
                return (
                  <div className="col-12 mb-3" key={key}>
                    <div className="form-group row">
                      <label className="form-label col-3 col-form-label">
                        {item.country_name}
                      </label>
                      <div className="col d-flex align-items-center">
                        <div className="input-group">
                          <span className="input-group-text">%</span>
                          <input
                            className="form-control"
                            type="number"
                            min={0}
                            max={100}
                            placeholder={`${item.country_name} ülkesi için vergi oranını giriniz.`}
                            name={`rates[${key}].rate`}
                            value={formik.values.rates?.[key]?.rate}
                            onChange={formik.handleChange}
                            onBlur={(event) => {
                              let newValue = parseInt(event.target.value);
                              newValue = isNaN(newValue)
                                ? 0
                                : newValue < 0
                                ? 0
                                : Math.min(newValue, 100);
                              formik.setFieldValue(
                                `rates[${key}].rate`,
                                newValue
                              );
                              formik.handleBlur(event);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="d-flex gap-2 justify-content-end w-100 mt-3">
              {tabIndex > 0 && (
                <Button
                  className="btn btn-ghost-primary py-1 px-3"
                  onClick={(e) => {
                    e.preventDefault();
                    if (tabIndex > 0) setTabIndex(tabIndex - 1);
                  }}
                  text="Geri Dön"
                />
              )}
              {tabIndex < tabs.length - 1 && (
                <Button
                  className="btn btn-ghost-primary py-1 px-3"
                  onClick={(e) => {
                    e.preventDefault();
                    if (tabIndex < tabs.length - 1) setTabIndex(tabIndex + 1);
                  }}
                  text="Devam Et"
                />
              )}
            </div>
          </Tab>
        </Tabs>
      </form>
    </>
  );
};
