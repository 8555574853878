import React, { Fragment, useEffect, useMemo, useState } from "react";
import { Modal } from "../Modal";
import { useTranslation } from "react-i18next";
import LanguageAPI from "../../api/LanguageAPI";
import * as Yup from "yup";
import { useFormik } from "formik";
import Button from "../Button";
import { findParentsTree, generateTree } from "../../utils/Functions";
import Select from "react-select";
import axios from "../../utils/CustomAxios";
import { useSelector } from "react-redux";
import CountryCityDistrictAPI from "../../api/CountryCityDistrictAPI";
import ProductAPI from "../../api/ProductAPI";
import { errorToast, successToast } from "../../utils/toastNotification";
import TaxAPI from "../../api/TaxAPI";
import { PlusIcon } from "../Icons";
import TaxForm from "./TaxForm";
import { useNavigate } from "react-router-dom";
import CategoryAPI from "../../api/CategoryAPI";
import TagAPI from "../../api/TagAPI";
import MultiSelect from "../MultiSelect";
import ProductSortableImage from "../../pages/product/ProductSortableImage";
import FilesDragAndDropUploader from "../FilesDragAndDropUploader";

const FastProductForm = ({ visible, setVisible, onSuccess = () => {} }) => {
  const { t } = useTranslation("common");
  const { shopDetail } = useSelector((state) => state.shop);
  const defaultSettings = shopDetail?.fast_product_settings;
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const [translations, setTranslations] = useState("");
  const [selectedLanguageIndex, setSelectedLanguageIndex] = useState(0);
  const [categories, setCategories] = useState([]);
  const [typeID, setTypeID] = useState(1);
  const [attributes, setAttributes] = useState([]);
  const [taxID, setTaxID] = useState(0);
  const [countries, setCountries] = useState([]);
  const [taxes, setTaxes] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [brands, setBrands] = useState([]);
  const [tags, setTags] = useState([]);
  const [units, setUnits] = useState([]);
  const [autoMETA, setAutoMETA] = useState(true);
  const [imageCounter, setImageCounter] = useState(0);

  const [taxModalOpen, setTaxModalOpen] = useState(false);

  const [priceInputTouched, setPriceInputTouched] = useState(false);

  //Variation
  const [selectedOptionIndexesID, setSelectedOptionIndexesID] = useState([]);

  const validationSchema = Yup.object().shape({
    mainProductCategoryID: Yup.object().shape({
      value: Yup.number()
        .notOneOf([0], t("validation.main_category"))
        .required(t("validation.main_category")),
      label: Yup.string().required(t("validation.main_category")),
    }),
    taxID: Yup.number()
      .notOneOf([0], t("validation.tax"))
      .required(t("validation.tax")),
    minQuantity: Yup.number()
      .min(1, t("validation.min"))
      .test("min-is-more-than-max", t("validation.min-max"), function (value) {
        const maxQuantity = this.parent.maxQuantity;
        return value <= maxQuantity;
      })
      .test("modulus-check", t("validation.min-mod"), function (value) {
        const stepQuantity = this.parent.stepQuantity;
        return value % stepQuantity === 0;
      }),
    maxQuantity: Yup.number()
      .min(1, t("validation.max"))
      .test("max-is-less-than-min", t("validation.max-min"), function (value) {
        const minQuantity = this.parent.minQuantity;
        return value >= minQuantity;
      }),
    stepQuantity: Yup.number().min(1, t("validation.max-mod")),
    translations: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required(t("validation.translation")),
      })
    ),
    variants: Yup.array()
      .test(
        "options-array-required",
        t("validation.options-required"),
        function (value) {
          if (typeID === 2) {
            // Her bir variant'ın içindeki options dizisini kontrol eder
            return value.every((variant) => {
              return variant.options && variant.options.length > 0;
            });
          }
          return true;
        }
      )
      .test(
        "variants-options-value-id-required",
        t("validation.variants-options-value-id-required"),
        function (value) {
          if (typeID === 2) {
            return value.every((variant) => {
              return variant.options.every(
                (option) => option.option_value_id !== 0
              );
            });
          }
          return true;
        }
      )
      .test(
        "variants-price-required",
        t("validation.variants-price-required"),
        function (value) {
          return value.every((variant) => {
            return variant.prices.every(
              (item) => item.price !== "" && item.price > 0
            );
          });
        }
      ),
  });

  const formik = useFormik({
    initialValues: {
      mainProductCategoryID: { value: 0, label: t("options.select") },
      selectedCategoriesID: [],
      taxID: defaultSettings?.tax ?? 0,
      unitID: defaultSettings?.unit ?? null,
      brandID: defaultSettings?.brand ?? null,
      selectedTagsID: defaultSettings?.tags ?? [],
      supplierID: defaultSettings?.supplier ?? null,
      minQuantity: defaultSettings?.min_quantity ?? 1,
      maxQuantity: defaultSettings?.max_quantity ?? 999,
      stepQuantity: defaultSettings?.step_quantity ?? 1,
      reviewStatus: defaultSettings?.review_status ?? false,
      refundStatus: defaultSettings?.refund_status ?? false,
      adultStatus: defaultSettings?.adult_status ?? false,
      status: defaultSettings?.status ?? true,
      translations: [],
      variants: [],
    },
    validationSchema: validationSchema,
    onSubmit: (values, { setSubmitting }) => {
      if (typeID !== 2) {
        formik.values.variants.forEach((el) => {
          el.options = [];
        });
      }

      if (values.selectedCategoriesID.length === 0) {
        values.selectedCategoriesID.push(+values.mainProductCategoryID);
      }

      addData(values);
    },
  });

  const categoriesTree = useMemo(
    () => generateTree(categories, null),
    [categories]
  );

  const recursiveOptions = (categories, prefix = "") => {
    let options = [];

    categories.forEach((category) => {
      options.push({
        value: category.id,
        label: prefix + category.name,
        isDisabled: category.children.length > 0,
      });

      if (category.children && category.children.length) {
        options = options.concat(
          recursiveOptions(category.children, prefix + "- ")
        ); // Alt kategorilere recursive olarak devam ediyoruz.
      }
    });

    return options;
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor:
        formik.touched.mainProductCategoryID &&
        formik.errors.mainProductCategoryID
          ? "#d63939" // hata durumu için renk
          : formik.touched.mainProductCategoryID
          ? "#2fb344" // başarılı durum için renk
          : provided.borderColor, // varsayılan renk
      // burada diğer stiller eklenebilir
    }),
    // Diğer react-select bileşen parçaları için de stiller eklenebilir, örneğin: valueContainer, singleValue, menuList, vs.
  };

  const getCategories = () => {
    return CategoryAPI.getAll({
      attributes: true,
    });
  };

  const getTaxes = () => {
    TaxAPI.getAll()
      .then((res) => {
        if (res.data.status === 1 && res.data.data && res.data.data.taxes) {
          setTaxes(res.data.data.taxes);
        }
      })
      .catch((err) => {
        errorToast(t, err);
      });
  };

  const getSuppliers = () => {
    return axios.get("v1/panel/suppliers", {
      params: {
        page: 0,
        limit: 100,
        order_index: "suppliers.id",
        order_direction: "desc",
      },
    });
  };

  const getBrands = () => {
    return axios.get("v1/panel/brands", {
      params: {
        page: 0,
        limit: 100,
        order_index: "brands.id",
        order_direction: "desc",
      },
    });
  };

  const getUnits = () => {
    return axios.get("v1/panel/units", {
      params: {
        page: 0,
        limit: 100,
        order_index: "units.id",
        order_direction: "desc",
      },
    });
  };

  const getDatas = () => {
    Promise.all([
      LanguageAPI.getAll(),
      getCategories(),
      CountryCityDistrictAPI.getCountries(),
      TaxAPI.getAll(),
      TagAPI.getAll(),
      getSuppliers(),
      getBrands(),
      getUnits(),
    ]).then(([res1, res2, res3, res4, res5, res6, res7, res8]) => {
      if (res1.data.status === 1) {
        setTranslations(
          res1.data.data.map((x, _index) => {
            //mağazanın varsayılan dilini seçili hale getirme
            if (x.id === shopDetail.default_language_id) {
              setSelectedLanguageIndex(_index);
            }

            return {
              id: 0,
              language_name: x.name,
              language_id: x.id,
              name: "",
              description: "",
              short_description: "",
              meta_title: "",
              meta_description: "",
              meta_keywords: "",
              auto_meta_keywords: [],
            };
          })
        );
        formik.setFieldValue(
          "translations",
          res1.data.data.map((x, _index) => {
            //mağazanın varsayılan dilini seçili hale getirme
            if (x.id === shopDetail.default_language_id) {
              setSelectedLanguageIndex(_index);
            }

            return {
              id: 0,
              language_name: x.name,
              language_id: x.id,
              name: "",
              description: "",
              short_description: "",
              meta_title: "",
              meta_description: "",
              meta_keywords: "",
              auto_meta_keywords: [],
            };
          })
        );

        if (res1.data.data.length && res1.data.data.length === 1) {
          setSelectedLanguageIndex(0);
        }
      }
      if (res2.data.status === 1) {
        let sortedCategories = res2.data.data.categories.sort((a, b) => {
          a = a.name || "";
          b = b.name || "";
          return a.localeCompare(b);
        });
        setCategories(sortedCategories);
      }
      if (res3.data.status === 1) {
        setCountries(res3.data.data);
      }
      if (res4.data.status === 1) {
        setTaxes(res4.data.data.taxes);
      }
      if (res5.data.status === 1) {
        setTags(res5.data.data.tags);
      }
      if (res6.data.status === 1) {
        setSuppliers(res6.data.data.suppliers);
      }
      if (res7.data.status === 1) {
        setBrands(res7.data.data.brands);
      }
      if (res8.data.status === 1) {
        setUnits(res8.data.data.units);
      }
    });
  };

  const addData = (model) => {
    setLoading(true);

    ProductAPI.create({
      product_type_id: typeID,
      main_category_id: model.mainProductCategoryID.value,
      categories: model.selectedCategoriesID,
      tax_id: model.taxID,
      unit_id: parseFloat(model.unitID) > 0 ? model.unitID : null,
      brand_id: parseFloat(model.brandID) > 0 ? model.brandID : null,
      tags: model.selectedTagsID,
      supplier_id: parseFloat(model.supplierID) > 0 ? model.supplierID : null,
      harmonized_system_code_id: null,
      group_code: null,
      min_quantity: model.minQuantity,
      max_quantity: model.maxQuantity,
      step_quantity: model.stepQuantity,
      preparation_time: 5,
      review_status: model.reviewStatus,
      refund_status: model.refundStatus,
      adult_status: model.adultStatus,
      status: model.status,
      translations: model.translations,
      variants: model.variants,
      selected_options: selectedOptionIndexesID
        .filter((x) => x !== -1)
        .map((x) => options[x].id),
      attributes: attributes
        .filter((x) => x.active)
        .flatMap((x) => x.selected_values),
      auto_meta: autoMETA,
    })
      .then((res) => {
        if (res.data.status === 1) {
          successToast(t, "saved");
          clearStorage();
          setVisible(false);
          onSuccess();
        }
        setLoading(false);
      })
      .catch((err) => {
        if (err.response.data.status === 2) {
          errorToast(t, "validation.stock-code-or-barcode-already-exist");
        } else {
          errorToast(t, "notSaved");
        }
        setLoading(false);
      });
  };

  const addOptionCombination = () => {
    //basit ürünse en fazla 1 adet eklebilir
    if (typeID === 1 && formik.values.variants.length > 0) {
      return;
    }

    // const defaultCurrencyID = currencies[0]["id"];

    formik.setFieldValue("variants", [
      ...formik.values.variants,
      {
        id: 0,
        is_default: formik.values.variants.length > 0 ? false : true,
        quantity: defaultSettings?.stock_quantity ?? 0,
        supplier_price: 0,
        supplier_code: null,
        stock_code: null,
        shelf: null,
        barcode: null,
        width: 0,
        height: 0,
        length: 0,
        weight: 0,
        status: true,
        selected_price_index: 0,
        prices: countries.map((x) => {
          return {
            id: 0,
            country_id: x.id,
            country_name: x.name,
            price: 1,
            strikethrough_price: null,
            status: true,
          };
        }),
        options: selectedOptionIndexesID
          .filter((x) => x !== -1)
          .map((_index) => ({
            option_id: options[_index].id,
            option_value_id: 0,
          })),
        images: [],
        deleted: false,
      },
    ]);
  };

  const onChangeProductMainCategoryID = (
    _categories,
    _attributes,
    _mainProductCategoryID,
    selected_attributes
  ) => {
    if (
      _attributes.length > 0 &&
      _categories.length > 0 &&
      _mainProductCategoryID !== 0
    ) {
      let selectedCategory = _categories.find(
        (x) => x.id === _mainProductCategoryID
      );

      if (selectedCategory !== undefined) {
        //seçilen ana kategori attribute idleri

        let selectedCategoryAttributes = selectedCategory.attributes;

        let newActiveAttributes = _attributes.map((x) => {
          return {
            ...x,
            active: selectedCategoryAttributes.includes(x.id),
            selected_values: [],
          };
        });

        setAttributes(newActiveAttributes);

        //kategorinin varsayılan kdv türünü seçtirme
        //seçilen ana kategorinin varsayılan kdv türünü seçili hale getirme
        setTaxID(selectedCategory.default_tax_id);
      }
    }
  };

  const onChangeMainCategoryID = (e) => {
    // const _mainProductCategoryID = parseInt(e.target.value);
    const _mainProductCategoryID = parseInt(e);

    onChangeProductMainCategoryID(
      categories,
      attributes,
      _mainProductCategoryID,
      []
    );

    //seçilen ana kategorinin üst kategorilerini seçili hale getirme
    const parentCategoryIDS = findParentsTree(
      {
        id: 0,
        children: categoriesTree,
      },
      _mainProductCategoryID
    );

    formik.setFieldValue("selectedCategoriesID", [
      ...new Set([...parentCategoryIDS]),
    ]);
  };

  const onChangeOptionValue = (optionCombinationIndex, key, value) => {
    let temp = [...formik.values.variants];

    if (key === "is_default") {
      temp = temp.map((item, key) => {
        return {
          ...item,
          is_default: optionCombinationIndex === key ? true : false,
        };
      });
    } else if (key === "options") {
      //aynı varyasyonlar seçilimi onları kontrol etme
      let existSameCombination = temp
        .filter((x) => !x.deleted)
        .filter((x) => !x.options.some((y) => y.option_value_id === 0)) //"seçiniz" olmayanlarda arama
        .findIndex((x) => JSON.stringify(x.options) === JSON.stringify(value));

      if (existSameCombination !== -1) {
        alert(t("same_options_selected"));
      } else {
        temp[optionCombinationIndex][key] = value;
      }
    } else if (key === "deleted") {
      if (confirm(t("delete?"))) {
        //fiyatlandırma en az 1 adet ve varsayılan değilse kaldırma gerçekleştir
        if (
          temp.length > 1 &&
          temp[optionCombinationIndex].is_default === false
        ) {
          temp[optionCombinationIndex].images.forEach((el) => {
            axios
              .get("v1/files/", {
                params: {
                  name: el.image,
                },
              })
              .then((res) => {
                if (res.data.status === 1) {
                  axios.delete("v1/files/" + res.data.data.id).then((res2) => {
                    if (res2.data.status === 1) {
                      // Local storage'dan tüm anahtarlarını getir
                      for (let i = 0; i < localStorage.length; i++) {
                        const key = localStorage.key(i);
                        // Sadece 'image' ile başlayan anahtarlarla ilgileniyoruz
                        if (key.startsWith("image")) {
                          const value = localStorage.getItem(key);
                          // Aradığımız değer
                          if (value === el.image) {
                            localStorage.removeItem(key);
                            break;
                          }
                        }
                      }
                    }
                  });
                }
              });
          });
          temp.splice(optionCombinationIndex, 1);
          successToast(t, "deleted");
        }
      }
    } else {
      temp[optionCombinationIndex][key] = value;
    }
    formik.setFieldValue("variants", temp);
  };

  const onChangeImageValue = (optionCombinationIndex, index, key, value) => {
    let temp = [...formik.values.variants];

    if (key === "deleted") {
      temp[optionCombinationIndex].images.splice(index, 1);
    } else if (key === "type") {
      if (value === 0) {
        temp[optionCombinationIndex].images[index][key] = value;
      } else {
        //ürün resim yüzü normal değilse
        temp[optionCombinationIndex].images = temp[
          optionCombinationIndex
        ].images.map((item2, key2) => {
          if (key2 === index) {
            return { ...item2, type: value };
          } else if (item2.type === value) {
            return { ...item2, type: 0 };
          } else {
            return { ...item2 };
          }
        });
      }
    } else {
      temp[optionCombinationIndex].images[index][key] = value;
    }

    formik.setFieldValue("variants", temp);
  };

  const onChangeOptionValuePrice = (index, key, value) => {
    let temp = [...formik.values.variants];
    temp[index]["prices"][temp[index].selected_price_index][key] = value;
    formik.setFieldValue("variants", temp);
  };

  //seçilen varyasyon değiştiğinde
  const onChangeSelectedOptionIndex = (key, value) => {
    //ilk baştakileri boş bırakamaz

    if (key !== 0 && selectedOptionIndexesID[key - 1] === -1) {
      return alert(t("selection_in_order"));
    }

    // silerken de aynı şekilde
    if (
      key !== selectedOptionIndexesID.length - 1 &&
      selectedOptionIndexesID[key + 1] !== -1
    ) {
      return alert(t("selection_in_order"));
    }

    let _selectedOptionIndexesID = [...selectedOptionIndexesID];
    _selectedOptionIndexesID[key] = value;
    setSelectedOptionIndexesID(_selectedOptionIndexesID);

    //ana varyasyonlar değiştiğinde değeleri sıfırlama işlemi
    let _variants = formik.values.variants.map((x) => {
      return {
        ...x,
        options: _selectedOptionIndexesID
          .filter((y) => y !== -1)
          .map((_index) => ({
            option_id: options[_index].id,
            option_value_id: 0,
          })),
      };
    });

    formik.setFieldValue("variants", _variants);
  };

  const onChangeTranslation = (key, value, key2 = null) => {
    let temp = [...formik.values.translations];
    if (key2 !== null) {
      temp[selectedLanguageIndex][key][key2] = value;
    } else {
      temp[selectedLanguageIndex][key] = value;
    }
    setTranslations(temp);
    formik.setFieldValue("translations", temp);
  };

  const clearStorage = () => {
    for (let i = localStorage.length - 1; i >= 0; i--) {
      const key = localStorage.key(i);
      if (key.startsWith("image")) {
        localStorage.removeItem(key);
      }
    }
  };

  useEffect(() => {
    getDatas();
  }, []);

  //1 tane option combination ekle
  useEffect(() => {
    if (countries.length > 0 && formik.values.variants.length === 0) {
      addOptionCombination();
    }
  }, [countries, formik.values.variants]);

  return (
    <>
      <Modal
        title={t("add_fast_product")}
        visible={visible}
        setVisible={setVisible}
      >
        <div
          style={{
            width: "100%",
            height: "70vh",
            overflow: "hidden",
            overflowY: "scroll",
          }}
          className="px-3"
        >
          <div className="btn-list d-flex justify-content-end mb-3">
            <Button
              text={t("save")}
              loading={loading}
              className="btn-primary"
              onClick={(e) => {
                setPriceInputTouched(true);
                formik.submitForm(e);
              }}
            />
          </div>
          <form onSubmit={formik.handleSubmit} className="row">
            <div className="col-12">
              <div className="col-12 mb-2">
                {(() => {
                  const error =
                    formik.errors.translations?.[selectedLanguageIndex]?.name;
                  const touched =
                    formik.touched.translations?.[selectedLanguageIndex]?.name;
                  const value =
                    formik.values.translations[selectedLanguageIndex]?.name ||
                    "";
                  const isValid = !error && touched && value !== "";

                  return (
                    <div className="form-group row">
                      <label className="form-label col-3 col-form-label required">
                        {t("labels.name")}
                      </label>
                      <div className="col">
                        <div className="row">
                          {formik.values.translations.length &&
                          formik.values.translations.length > 1 ? (
                            <div className="col-12 mb-2">
                              <div className="btn-group w-100 overflow-auto">
                                {formik.values.translations.map((item, key) => {
                                  return (
                                    <span
                                      key={key}
                                      onClick={() =>
                                        setSelectedLanguageIndex(key)
                                      }
                                      className={
                                        "btn " +
                                        (selectedLanguageIndex === key
                                          ? "btn-primary"
                                          : "")
                                      }
                                    >
                                      {item.language_name}
                                    </span>
                                  );
                                })}
                              </div>
                            </div>
                          ) : null}
                          <div className="col-12">
                            <input
                              type="text"
                              className={`form-control ${
                                touched && error
                                  ? "is-invalid is-invalid-lite"
                                  : isValid
                                  ? "is-valid is-valid-lite"
                                  : ""
                              }`}
                              name={`translations[${selectedLanguageIndex}].name`}
                              value={value}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            {touched && error && (
                              <div className="invalid-feedback">{error}</div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })()}
              </div>
            </div>
            <div className="col-12 mb-2">
              <div className="form-group row">
                <label className="form-label col-3 col-form-label required">
                  {t("labels.main_category")}
                </label>
                <div className="col">
                  <Select
                    classNamePrefix="react-select"
                    value={formik.values.mainProductCategoryID}
                    options={recursiveOptions(categoriesTree)}
                    onChange={(option) => {
                      formik.setFieldValue("mainProductCategoryID", option);
                      onChangeMainCategoryID(option.value);
                    }}
                    onBlur={() =>
                      formik.setFieldTouched("mainProductCategoryID")
                    }
                    isSearchable={true}
                    styles={customStyles}
                  />

                  {formik.touched.mainProductCategoryID &&
                  formik.errors.mainProductCategoryID ? (
                    <div
                      style={{
                        color: "#d63939",
                        fontSize: "12px",
                        margin: "4px 0 0",
                      }}
                    >
                      {t(formik.errors.mainProductCategoryID.value)}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="col-12 mb-2">
                <div className="form-group row">
                  <label className="form-label col-3 col-form-label required">
                    {t("labels.tax")}
                  </label>
                  <div className="col">
                    <select
                      className={`form-control ${
                        formik.touched.taxID
                          ? formik.errors.taxID
                            ? "is-invalid is-invalid-lite"
                            : "is-valid is-valid-lite"
                          : ""
                      }`}
                      id="taxID"
                      name="taxID"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.taxID}
                    >
                      <option value={0}>{t("options.select")}</option>
                      {taxes.map((item, key) => {
                        return (
                          <option key={key} value={item.id}>
                            {t(item.name)}
                          </option>
                        );
                      })}
                    </select>

                    {formik.touched.taxID && formik.errors.taxID ? (
                      <div className="invalid-feedback">
                        {t(formik.errors.taxID)}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="col-12 mb-2">
                {formik.values.variants
                  .filter((x) => !x.deleted)
                  .map((item, key) => (
                    <>
                      <div className="form-group row">
                        <label className="form-label col-3 col-form-label required">
                          {t("labels.stock")}
                        </label>
                        <div className="col">
                          {" "}
                          <input
                            type="text"
                            className="form-control"
                            value={item.quantity}
                            onChange={(e) =>
                              onChangeOptionValue(
                                key,
                                "quantity",
                                e.target.value
                              )
                            }
                          />
                        </div>
                      </div>
                    </>
                  ))}
              </div>
              <div className="col-12">
                <div className="row">
                  <div className="col-12 col-lg-4 mb-2">
                    <div className="input-group">
                      <span className="input-group-text">
                        {t("labels.min_buying")}
                      </span>
                      <input
                        type="number"
                        pattern="[0-9.]+"
                        className={`form-control`}
                        id="minQuantity"
                        name="minQuantity"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.minQuantity}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-lg-4 mb-2">
                    <div className="input-group">
                      <span className="input-group-text">
                        {t("labels.max_buying")}
                      </span>
                      <input
                        type="number"
                        pattern="[0-9.]+"
                        className={`form-control`}
                        id="maxQuantity"
                        name="maxQuantity"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.maxQuantity}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-lg-4 mb-2">
                    <div className="input-group">
                      <span className="input-group-text">
                        {t("labels.increment")}
                      </span>
                      <input
                        type="number"
                        pattern="[0-9.]+"
                        className={`form-control`}
                        id="stepQuantity"
                        name="stepQuantity"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.stepQuantity}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 mb-2">
                {formik.values.variants
                  .filter((x) => !x.deleted)
                  .map((item, key) => (
                    <>
                      <div className="col-12">
                        <div className=" mb-0">
                          <div className="col-12">
                            <div className="form-group row">
                              <label className="form-label col-3 col-form-label required">
                                {t("labels.selling_price")}
                              </label>
                              <div className="col">
                                {item.prices.length > 1 && (
                                  <div className="col-12 mb-2">
                                    <div className="btn-group w-100 overflow-auto">
                                      {item.prices.map((item2, key2) => {
                                        return (
                                          <span
                                            key={key2}
                                            onClick={(e) =>
                                              onChangeOptionValue(
                                                key,
                                                "selected_price_index",
                                                key2
                                              )
                                            }
                                            className={
                                              "btn " +
                                              (item.selected_price_index ===
                                              key2
                                                ? "btn-primary"
                                                : "")
                                            }
                                          >
                                            {item2.country_name}
                                          </span>
                                        );
                                      })}
                                    </div>
                                  </div>
                                )}
                                <div className="col-12">
                                  <div className="input-group">
                                    <span className="input-group-text">
                                      {`${
                                        shopDetail.currency_left_symbol || ""
                                      }${
                                        shopDetail.currency_right_symbol || ""
                                      }`}
                                    </span>
                                    {(() => {
                                      const error =
                                        formik.errors?.variants ===
                                        t("validation.variants-price-required");
                                      const value =
                                        item.prices[item.selected_price_index]
                                          .price;
                                      const isValid =
                                        !error &&
                                        priceInputTouched &&
                                        value !== "" &&
                                        value > 0;
                                      return (
                                        <>
                                          <input
                                            type="number"
                                            className={`form-control ${
                                              priceInputTouched && error
                                                ? "is-invalid is-invalid-lite"
                                                : isValid
                                                ? "is-valid is-valid-lite"
                                                : ""
                                            }`}
                                            value={
                                              item.prices[
                                                item.selected_price_index
                                              ].price
                                            }
                                            onChange={(e) => {
                                              onChangeOptionValuePrice(
                                                key,
                                                "price",
                                                e.target.value
                                              );
                                            }}
                                            onBlur={() =>
                                              setPriceInputTouched(true)
                                            }
                                          />
                                          {priceInputTouched && error ? (
                                            <div className="invalid-feedback">
                                              {formik.errors?.variants}
                                            </div>
                                          ) : null}
                                        </>
                                      );
                                    })()}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ))}
              </div>

              <div className="col-12 mb-2">
                <div className="form-group row">
                  <label className="form-label col-3 col-form-label">
                    {t("labels.unit")}
                  </label>
                  <div className="col">
                    <select
                      className={`form-control ${
                        formik.touched.unitID
                          ? formik.errors.unitID
                            ? "is-invalid is-invalid-lite"
                            : "is-valid is-valid-lite"
                          : ""
                      }`}
                      id="unitID"
                      name="unitID"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.unitID}
                    >
                      <option value={0}>{t("options.select")}</option>
                      {units.map((item, key) => {
                        return (
                          <option value={item.id} key={key}>
                            {t(item.name)}
                          </option>
                        );
                      })}
                    </select>

                    {formik.touched.unitID && formik.errors.unitID ? (
                      <div className="invalid-feedback">
                        {t(formik.errors.unitID)}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="col-12 mb-2">
                <div className="form-group row">
                  <label className="form-label col-3 col-form-label">
                    {t("labels.brand_name")}
                  </label>
                  <div className="col">
                    <select
                      className={`form-control ${
                        formik.touched.brandID
                          ? formik.errors.brandID
                            ? "is-invalid is-invalid-lite"
                            : "is-valid is-valid-lite"
                          : ""
                      }`}
                      id="brandID"
                      name="brandID"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.brandID}
                    >
                      <option value={0}>{t("options.select")}</option>
                      {brands.map((item, key) => {
                        return (
                          <option key={key} value={item.id}>
                            {t(item.name)}
                          </option>
                        );
                      })}
                    </select>

                    {formik.touched.brandID && formik.errors.brandID ? (
                      <div className="invalid-feedback">
                        {t(formik.errors.brandID)}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>

              <div className="col-12 mb-2">
                <div className="form-group row">
                  <label className="form-label col-3 col-form-label">
                    {t("labels.tags")}
                  </label>
                  <div className="col">
                    <MultiSelect
                      optionKey="id"
                      optionValueKey="name"
                      options={tags}
                      values={formik.values.selectedTagsID}
                      onChange={(selectedOptions) => {
                        formik.setFieldValue(
                          "selectedTagsID",
                          selectedOptions.map((option) => option)
                        );
                      }}
                      onBlur={() =>
                        formik.setFieldTouched("selectedTagsID", true)
                      }
                      error={formik.errors.selectedTagsID}
                      touched={formik.touched.selectedTagsID}
                    />
                  </div>
                </div>
              </div>

              <div className="col-12 mb-2">
                <div className="form-group row">
                  <label className="form-label col-3 col-form-label">
                    {t("labels.supplier")}
                  </label>
                  <div className="col">
                    <select
                      className={`form-control ${
                        formik.touched.supplierID
                          ? formik.errors.supplierID
                            ? "is-invalid is-invalid-lite"
                            : "is-valid is-valid-lite"
                          : ""
                      }`}
                      id="supplierID"
                      name="supplierID"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.supplierID}
                    >
                      <option value={0}>{t("options.select")}</option>
                      {suppliers.map((item3, key3) => {
                        return (
                          <option value={item3.id} key={key3}>
                            {item3.name}
                          </option>
                        );
                      })}
                    </select>

                    {formik.touched.supplierID && formik.errors.supplierID ? (
                      <div className="invalid-feedback">
                        {t(formik.errors.supplierID)}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 mb-2">
              <div className="form-group row align-items-center">
                <label className="form-label col-3 mb-0 col-form-label">
                  {t("labels.review")}
                </label>
                <div className="col">
                  <label className="form-check form-switch mb-0">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="reviewStatus"
                      name="reviewStatus"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      checked={formik.values.reviewStatus}
                    />
                  </label>
                </div>
              </div>
            </div>

            <div className="col-12 mb-2">
              <div className="form-group row align-items-center">
                <label className="form-label mb-0 col-3 col-form-label">
                  {t("labels.refund")}
                </label>
                <div className="col">
                  <label className="form-check form-switch mb-0">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="refundStatus"
                      name="refundStatus"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      checked={formik.values.refundStatus}
                    />
                  </label>
                </div>
              </div>
            </div>

            <div className="col-12 mb-2">
              <div className="form-group row align-items-center">
                <label className="form-label mb-0 col-3 col-form-label">
                  {t("labels.adult")}
                </label>
                <div className="col">
                  <label className="form-check form-switch mb-0">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="adultStatus"
                      name="adultStatus"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      checked={formik.values.adultStatus}
                    />
                  </label>
                </div>
              </div>
            </div>

            <div className="col-12 mb-2">
              <div className="form-group row align-items-center">
                <label className="form-label mb-0 col-3 col-form-label">
                  {t("labels.status")}
                </label>
                <div className="col">
                  <label className="form-check form-switch mb-0">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="status"
                      name="status"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      checked={formik.values.status}
                    />
                  </label>
                </div>
              </div>
            </div>

            <div className="col-12 mb-2">
              {formik.values.variants
                .filter((x) => !x.deleted)
                .map((item, key) => (
                  <>
                    <div className="col-12 mb-0">
                      <div className="form-group row">
                        <label className="col-3 form-label col-form-label">
                          {t("product_images")}
                        </label>
                        <div className="col">
                          <fieldset className="form-fieldset">
                            <div className="row">
                              <div className="col-12 mb-2">
                                <FilesDragAndDropUploader
                                  type="product"
                                  count={5}
                                  onUploaded={(file) => {
                                    //ön resim mevcutmu kontrolü
                                    let checkFrontImage =
                                      formik.values.variants[key].images.some(
                                        (x) =>
                                          x.type === 1 && x.deleted === false
                                      );

                                    // yeni resmi arraya dahil etme
                                    onChangeOptionValue(key, "images", [
                                      ...formik.values.variants[key].images,
                                      {
                                        id: 0,
                                        type: checkFrontImage ? 0 : 1,
                                        image: file.name,
                                        url: file.url,
                                        status: true,
                                        deleted: false,
                                      },
                                    ]);

                                    setImageCounter((prevCounter) => {
                                      // Önceki counter değeri ile localStorage güncellemesi
                                      localStorage.setItem(
                                        "image" + key + prevCounter,
                                        file.name
                                      );

                                      // counter'ı 1 artır
                                      return prevCounter + 1;
                                    });
                                  }}
                                />
                              </div>

                              <div className="col-12">
                                <ProductSortableImage
                                  items={item.images.filter(
                                    (x) => x.deleted === false
                                  )}
                                  setItems={(datas) => {
                                    let temp = [...formik.values.variants];
                                    temp[key].images = datas;
                                    formik.setFieldValue("variants", temp);
                                  }}
                                  onChangeImageValue={onChangeImageValue}
                                  optionCombinationIndex={key}
                                  t={t}
                                />
                              </div>
                            </div>
                          </fieldset>
                        </div>
                      </div>
                    </div>
                  </>
                ))}
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
};

export default FastProductForm;
